import {
	Button, FormControl,
	Radio, TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import produce from 'immer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from '../styles/Survey.module.css'
import { Submission } from '../user-data/user-data'
import { Option, setObjectProperty } from '../utils'
import { SurveyProps } from '../utils/SurveyProps'

const yesNoOptions = [
	{
		'name': 'yes',
		'value': 1
	},
	{
		'name': 'no',
		'value': 0
	},
]

const yesNoQuestions = ['pregnant', 'givenBirth', 'breastfeeding', 'highBloodPressure', 'smoking', 'bloodClotting']

export const MedicalHistory = (props: SurveyProps): JSX.Element => {
	const [medicalConditionsArray, setMedicalConditionsArray] = React.useState<Option[]>([])
	const [medicationsArray, setMedicationsArray] = React.useState<Option[]>([])

	const [willUpdate, setWillUpdate] = React.useState<boolean>(false)
	const [state, setState] = React.useState<Partial<Submission>>({
		medicalHistory: [],
		medicalConditions: [],
		otherConditions: "",
		medications: [],
		otherMedications: "",
	})

	const { t, i18n } = useTranslation()

	// Setters
	const handleChange = (property: string, event: any, value: any | null) => {
		setState(prevState => produce(prevState, state => {
			setObjectProperty(state, property, value)
		}))
	}

	const beforeSubmit = (event: any) => {
		event.preventDefault()

		props.setUser((prevUser) => produce(prevUser, (user) => {
			user.data.medicalHistory = state.medicalHistory

			user.data.medicalConditions = state.medicalConditions
			user.data.otherConditions = state.otherConditions

			user.data.medications = state.medications
			user.data.otherMedications = state.otherMedications
		}))

		setWillUpdate(true)
	}

	// fill in state from props when it's ready
	React.useEffect(() => {
		// setUserData(props.userDataJson);
		if (willUpdate) {
			props.onSubmit(null)
			setWillUpdate(false)
		}

		const fetchMedicalConditionsArray = async () => {
			const response = await i18n.getResource(i18n.language, 'survey', 'medicalConditions')

			const medicalConditions: Option[] = []
			for (const [key, value] of Object.entries(response)) {
				medicalConditions.push({
					name: value as string,
					value: key,
				})
			}
			setMedicalConditionsArray(medicalConditions)
		}

		if (!medicalConditionsArray.length) {
			fetchMedicalConditionsArray()
		}

		const fetchMedicationsArray = async () => {
			const response = await i18n.getResource(i18n.language, 'survey', 'medications')

			const arr: Option[] = []
			for (const [key, value] of Object.entries(response)) {
				arr.push({
					name: value as string,
					value: key,
				})
			}
			setMedicationsArray(arr)
		}

		if (!medicationsArray.length) {
			fetchMedicationsArray()
		}
	}, [willUpdate, props, medicalConditionsArray, medicationsArray, i18n])

	return (
		<section>
			<fieldset className={classes.formStep}>

				<h2 className={`title-h5 ${classes.formTitle}`}>
					{t('survey.medicalHistory')}
				</h2>

				<div className={classes.multipleQuestions}>
					{yesNoQuestions.map(function (question, index) {
						const negatedName = `not_${question}`
						const indexInMedicalHistory = state.medicalHistory!.indexOf(question)
						const negatedIndex = state.medicalHistory!.indexOf(negatedName)
						const isNegated = negatedIndex > -1
						const isSelected = indexInMedicalHistory > -1
						return (
							<FormControl key={index} component="fieldset" className={classes.question}>
								<p>{t('survey:questions.' + question + '.question')}</p>
								<div>
									{yesNoOptions.map(function (option, index) {
										return (
											<label className={classes.optionLabel} key={index}>
												<Radio
													checked={option.value > 0 ? isSelected : isNegated}
													onChange={() => {
														if (option.value > 0) {
															setState(prevState => produce(prevState, (state) => {
																state.medicalHistory!.push(question)
																if (negatedIndex > -1) {
																	state.medicalHistory!.splice(negatedIndex, 1)
																}
															}))
														} else {
															setState(prevState => produce(prevState, (state) => {
																state.medicalHistory!.push(negatedName)
																if (indexInMedicalHistory > -1) {
																	state.medicalHistory!.splice(indexInMedicalHistory, 1)
																}
															}))
														}
													}}
													value={option.value}
													name={question}
													inputProps={{ 'aria-label': t('survey:questions.' + question + '.question') }}
												/>
												{t("survey:questions.yesno." + option.name)}
											</label>
										)
									})}
								</div>
							</FormControl>
						)
					})}

					{yesNoQuestions.map(function (option, index) {
						return (
							<div key={index} className={classes.infoBox} aria-hidden={state.medicalHistory!.indexOf(option) === -1}>
								{t('survey:questions.' + option + '.warning')}
							</div>
						)
					})}
				</div>

				<h2 className={`title-h6 ${classes.formTitle}`}>
					{t('survey.medicalConditions')}
				</h2>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} id="medicalConditions_label">{t('survey:questions.medicalConditions')}</label>

					<Autocomplete
						multiple
						id="medicalConditions"
						options={medicalConditionsArray}
						getOptionLabel={(option) => option.name}
						isOptionEqualToValue={(option, value) => option.value === value.value}
						onChange={(event, value) => {
							const selectedValues = value.map(obj => obj.value)
							handleChange('medicalConditions', event, selectedValues)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder={t('survey:questions.selectAll')}
							/>
						)}
					/>
				</FormControl>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label}>{t('survey:questions.medicalConditions_others')}</label>
					<TextField
						id="otherConditions"
						variant="outlined"
						value={state.otherConditions}
						fullWidth={true}
						onChange={(event) => {
							handleChange('otherConditions', event, event.target.value)
						}}
					/>
				</FormControl>

				<h2 className={`title-h6 ${classes.formTitle}`}>
					{t('survey.medication')}
				</h2>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} id="medications_label">{t('survey:questions.medication')}</label>
					<Autocomplete
						multiple
						id="medications"
						options={medicationsArray}
						getOptionLabel={(option) => option.name}
						onChange={(event, value) => {
							const selectedValues = value.map(obj => obj.value)
							handleChange('medications', event, selectedValues)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder={t('survey:questions.selectAll')}
							/>
						)}
					/>
				</FormControl>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label}>{t('survey:questions.medication_others')}</label>
					<TextField
						id="otherMedications"
						variant="outlined"
						value={state.otherMedications}
						fullWidth={true}
						onChange={(event) => {
							handleChange('otherMedications', event, event.target.value)
						}}
					/>
				</FormControl>
			</fieldset>

			<Button type="submit"
				onClick={beforeSubmit}
				className={classes.btn_submit}
				variant="contained"
			>
				{t('survey.save')}
			</Button>
		</section>
	)

}