import {
	Button, FormControl, Radio, TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import produce from 'immer'
import { Moment } from 'moment'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from '../styles/Survey.module.css'
import { setObjectProperty } from '../utils'
import { SurveyProps } from '../utils/SurveyProps'

const countryOptions = ['', 'USA', 'CA', 'other']

const yesNoOptions = [
	{
		'name': 'yes',
		'value': 1
	},
	{
		'name': 'no',
		'value': 0
	},
]

interface State {
	country: string
	dateOfBirthMs: number
	isInsured: boolean
	isForTesting: boolean
}

export const PersonalInformation = (props: SurveyProps): JSX.Element => {

	const [willUpdate, setWillUpdate] = React.useState<boolean>(false)
	const [state, setState] = React.useState<State>({
		country: '',
		// Default to Dec 31 (so that format is clear) but 20 years ago since most users will probably be around that age.
		dateOfBirthMs: new Date(new Date().getFullYear() - 20, 11, 31).getTime(),
		isInsured: false,
		isForTesting: false,
	})

	const { t } = useTranslation()

	const handleChange = (property: string, value: any | null) => {
		setState(prevState => produce(prevState, state => {
			setObjectProperty(state, property, value)
		}))
	}

	const beforeSubmit = (event: any) => {
		event.preventDefault()

		if (state.country !== '') {
			props.setUser((prevUser) => produce(prevUser, (user) => {
				user.data.user.country = state.country
				user.data.user.dateOfBirthMs = state.dateOfBirthMs
				user.data.user.isInsured = state.isInsured
				user.data.isForTesting = state.isForTesting
			}))

			setWillUpdate(true)

		} else {
			console.error("Cannot submit because the country is not set.")
		}
	}


	React.useEffect(() => {
		if (willUpdate) {
			props.onSubmit(null)
			setWillUpdate(false)
		}
		// TODO @todo: prefill fields when possible
		/*
		setUserProperty('country', state.country);
		setUserProperty('dateOfBirthMs', state.dateOfBirth);
		setUserProperty('isInsured', state.isInsured);
		setState({
			'country': getUserProperty('data.user.country', ''),
			'dateOfBirthMs': getUserProperty('data.user.dateOfBirth', new Date()),
			'isInsured': getUserProperty('data.user.isInsured', ''),
		})
		setState({
			'country': user.data.user.country,
			'dateOfBirth': user.data.user.dateOfBirth,
			'isInsured': user.data.user.isInsured,
		})
		*/

	}, [willUpdate, props])


	return (
		<section>
			<fieldset className={classes.formStep}>

				<h2 className={`title-h5 ${classes.formTitle}`}>
					{t('survey.personalInformation.title')}
				</h2>
				<p>
					{t('survey.personalInformation.intro')}
				</p>

				<hr />

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} id="country_label">{t('survey:questions.country.question')}</label>

					<Autocomplete
						id="country"
						value={state.country}
						options={countryOptions}
						blurOnSelect={true}
						getOptionLabel={(option) => {
							if (option === '') {
								return t("survey:questions.country.choices.select")
							} else {
								return t("survey:questions.country.choices." + option)
							}
						}}
						onChange={(_, value) => {
							handleChange('country', value)
						}}
						renderInput={(params) => <TextField {...params} placeholder={t('survey:questions.country.choices.select')} variant="outlined" required />}
					/>
					<div className={classes.infoBox} aria-hidden={state.country !== 'other'}>Pill0 is currently not available in your area.</div>
				</FormControl>

				<FormControl className={classes.formControl}>
					{/* @TODO: localize */}
					<LocalizationProvider dateAdapter={AdapterMoment}>
						<DatePicker
							label={t("survey:questions.dob")}
							inputFormat={t('common.date')}
							value={state.dateOfBirthMs}
							onChange={(dateOfBirth: unknown) => {
								// `dateOfBirth` is a moment object but TypeScript says it's a `number`.
								if (dateOfBirth) {
									handleChange('dateOfBirthMs', (dateOfBirth as Moment).valueOf())
								}
							}}
							disableFuture={true}
							renderInput={(params) => <TextField {...params} />}
						/>
					</LocalizationProvider>
				</FormControl>

				<FormControl component="fieldset" className={classes.question}>

					<p>{t('survey:questions.insurance.question')}</p>
					<div>
						{yesNoOptions.map(function (option, index) {
							const isNegated = state.isInsured === false
							const isSelected = state.isInsured === true
							return (
								<label className={classes.optionLabel} key={index}>
									<Radio
										value={option.value}
										checked={option.value > 0 ? isSelected : isNegated}
										onChange={(event) => {
											const v = (event.target.value === '0') ? false : true
											handleChange('isInsured', v)
										}}
										name="isInsured"
										inputProps={{ 'aria-label': t('survey:questions.insurance') }}
									/>
									{t("survey:questions.yesno." + option.name)}
								</label>
							)
						})}
					</div>
				</FormControl>
				<div className={classes.infoBox} aria-hidden={state.isInsured}>
					{t("survey:questions.insurance.notice")}
				</div>

				<FormControl component="fieldset" className={classes.question}>

					<p>{t('survey:questions.isForTesting.question')}</p>
					<div>
						{yesNoOptions.map(function (option, index) {
							const isNegated = state.isForTesting === false
							const isSelected = state.isForTesting === true
							return (
								<label className={classes.optionLabel} key={index}>
									<Radio
										value={option.value}
										checked={option.value > 0 ? isSelected : isNegated}
										onChange={(event) => {
											const v = (event.target.value === '0') ? false : true
											handleChange('isForTesting', v)
										}}
										name="isForTesting"
										inputProps={{ 'aria-label': t('survey:questions.isForTesting.question') }}
									/>
									{t("survey:questions.yesno." + option.name)}
								</label>
							)
						})}
					</div>
				</FormControl>
				<div className={classes.infoBox} aria-hidden={state.isForTesting !== true}>
					{t("survey:questions.isForTesting.notice")}
				</div>
			</fieldset>

			<p>
				{t("survey:consent_notice")}
			</p>

			<Button type="submit"
				onClick={beforeSubmit}
				className={classes.btn_submit}
				variant="contained"
				color="primary"
			>
				{t('survey.save')}
			</Button>
		</section>
	)
}