export default class ListHelper {
	private maxDescriptionLength = 110
	private descriptionSuffix = "..."

	public getStyles(): any {
		return {
			search: {
				marginTop: 6,
			},
			cardContainer: {
				alignItems: 'stretch',
				marginTop: 2,
			},
			card: {
				display: 'flex',
				flexDirection: 'column',
				// To keep the card buttons at the bottom of the card.
				justifyContent: 'space-between',
				height: '100%',
			},
			cardContent: {
				// To keep the card buttons at the bottom of the card.
				display: 'flex',
				flex: '1 0 auto',
				flexDirection: 'column',
			},
			cardActions: {
				display: 'flex',
			},
		}
	}

	public truncateDescription(description: string): string {
		if (!description) {
			return description
		}
		if (description.length + this.descriptionSuffix.length > this.maxDescriptionLength) {
			return description.slice(0, this.maxDescriptionLength - this.descriptionSuffix.length) + this.descriptionSuffix
		}
		return description
	}
}
