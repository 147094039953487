import {
	Button, FormControl,
	FormControlLabel, MenuItem,
	Radio, RadioGroup, Select, TextField
} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import produce from 'immer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DataProvider, TreatmentBrand } from '../data-retrieval/DataProvider'
import classes from '../styles/Survey.module.css'
import { TreatmentHistory } from '../user-data/user-data'
import { getObjectProperty, Option, uuidv4 } from '../utils'
import { SurveyProps } from '../utils/SurveyProps'

export const BirthControl = (props: SurveyProps): JSX.Element => {
	const [brands, setBrands] = React.useState<TreatmentBrand[] | undefined>(undefined)
	const [treatmentMotivationsArray, setTreatmentMotivationsArray] = React.useState<Option[] | undefined>(undefined)
	const [sideEffects, setSideEffects] = React.useState<Option[] | undefined>(undefined)

	const [willUpdate, setWillUpdate] = React.useState(false)

	const [state, setState] = React.useState({
		treatmentMotivations: [] as Option[],
		otherTreatmentMotivations: '',
		// TODO Change it so that's actually TreatmentHistorys.
		// It's similar but some lists are Options instead of strings.
		treatmentHistory: [] as Partial<TreatmentHistory>[],
		otherTreatmentsUsed: [] as Option[],
		preferredPeriodFrequency: '',
		concernedAbout: [] as Option[],
	})


	const { t, i18n } = useTranslation()

	const periodFrequencyOptions: Option[] = [
		{
			name: t('survey:questions.preferredPeriodFrequency.choices.monthly'),
			value: 'monthly'
		},
		{
			name: t('survey:questions.preferredPeriodFrequency.choices.rarely'),
			value: 'rarely'
		}
	]
	const durations: Option[] = [
		{
			name: t('survey:questions.treatmentDuration.choices.less-than-90'),
			value: 'less-than-90'
		},
		{
			name: t('survey:questions.treatmentDuration.choices.90-365'),
			value: '90-365'
		},
		{
			name: t('survey:questions.treatmentDuration.choices.365+'),
			value: '365+'
		}
	]
	const otherContraceptionUsedOptions: Option[] = [
		{
			name: t('survey:questions.otherContraceptionUsed.choices.iud'),
			value: 'iud'
		},
		{
			name: t('survey:questions.otherContraceptionUsed.choices.implant'),
			value: 'implant'
		},
		{
			name: t('survey:questions.otherContraceptionUsed.choices.patch'),
			value: 'patch'
		},
		{
			name: t('survey:questions.otherContraceptionUsed.choices.injection'),
			value: 'injection'
		},
		{
			name: t('survey:questions.otherContraceptionUsed.choices.ring'),
			value: 'ring'
		}
	]

	// Setters
	const handleChange = (property: string, event: any, value: any | null) => {
		setState(prevState => ({ ...prevState, [property]: value }))
	}

	const beforeSubmit = (event: any) => {
		event.preventDefault()

		// @todo: validation
		props.setUser((prevUser) => produce(prevUser, (user) => {

			// Birth control motivation - array of strings
			if (state.treatmentMotivations) {
				const newTreatmentMotivations: string[] = []
				state.treatmentMotivations.forEach((item: any) => {
					newTreatmentMotivations.push(item.value)
				})
				user.data.treatmentMotivations = newTreatmentMotivations
			}

			// Additional info on birth control motivation - string
			user.data.otherTreatmentMotivations = state.otherTreatmentMotivations

			if (state.treatmentHistory) {
				// Birth control history - big object. multiple.
				const newTreatmentHistory: TreatmentHistory[] = []
				state.treatmentHistory.forEach((item) => {
					const newTreatment = {
						idFromSource: item.idFromSource,
						duration: item.duration,
						treatmentId: item.treatmentId,
						brandId: item.brandId,
					} as TreatmentHistory
					newTreatment.reviewLang = item.reviewLang
					newTreatment.review = item.review
					newTreatment.rating = item.rating
					newTreatment.dose = item.dose

					const newSideEffects: string[] = []
					if (item.sideEffects) {
						(item.sideEffects as unknown as Option[]).forEach((sideEffect) => {
							if (sideEffect.value) {
								newSideEffects.push(sideEffect.value)
							}
						})
					}
					newTreatment.sideEffects = newSideEffects

					const newTreatmentEffects: string[] = []
					if (item.treatmentEffects) {
						(item.treatmentEffects as unknown as Option[]).forEach((treatmentEffect) => {
							if (treatmentEffect.value) {
								newTreatmentEffects.push(treatmentEffect.value)
							}
						})
					}
					newTreatment.treatmentEffects = newTreatmentEffects

					newTreatmentHistory.push(newTreatment)
				})
				user.data.treatmentHistory = newTreatmentHistory
			}

			if (state.otherTreatmentsUsed) {
				// Other contraception used - array of strings
				const newOtherTreatmentsUsed: string[] = []
				state.otherTreatmentsUsed.forEach((item: any) => {
					newOtherTreatmentsUsed.push(item.value)
				})
				user.data.otherTreatmentsUsed = newOtherTreatmentsUsed
			}

			// Prefered period duration - string
			user.data.preferredPeriodFrequency = state.preferredPeriodFrequency

			if (state.concernedAbout) {
				// Concerned about - array of strings
				const newConcernedAbout: string[] = []
				state.concernedAbout.forEach((item) => {
					newConcernedAbout.push(item.value)
				})
				user.data.concernedAbout = newConcernedAbout
			}
		}))

		setWillUpdate(true)
	}

	const addPreviousTreatment = (brand: TreatmentBrand) => {
		setState(prevState => produce(prevState, state => {
			state.treatmentHistory!.push({
				idFromSource: uuidv4(),
				brandId: brand.id,
				treatmentId: brand.treatmentId,
				duration: '',
				sideEffects: [],
				treatmentEffects: [],
				otherTreatmentEffects: '',
				reviewLang: "en",
				review: "",
				rating: undefined,
				dose: {
					// FIXME Maybe this shouldn't default?
					style: "21-7",
				},
			})
		}))
	}

	const handleChangePreviousTreatmentChange = (property: string, index: number, value: any | null) => {
		setState(prevState => produce(prevState, state => {
			(state.treatmentHistory![index] as any)[property] = value
		}))
	}

	let userCountry = getObjectProperty(props.user, 'data.user.country', 'USA').toUpperCase() as string
	if (userCountry === 'OTHER') { userCountry = 'USA' }
	else if (userCountry === 'CANADA') { userCountry = 'CA' }

	const dataProvider = new DataProvider()

	React.useEffect(() => {
		if (brands === undefined) {
			// FIXME Add back filtering by country when more countries are added to pills.
			// Right now there is only 1 pill for Canada.
			dataProvider.getBrands(/* userCountry */).then(setBrands)
		}
	}, [brands, dataProvider, userCountry])

	React.useEffect(() => {
		if (willUpdate) {
			props.onSubmit(null)
			setWillUpdate(false)
		}

		// Fetch Side Effects
		const fetchSideEffects = async () => {
			// TODO Lang pass locale.
			const response = (await dataProvider.getAllEffects()).map(e => { return { name: e.name['en'], value: e.id } })
			response.sort((e1, e2) => e1.name.localeCompare(e2.name))

			setSideEffects(response)
		}

		if (sideEffects === undefined) {
			fetchSideEffects()
		}

		// Fetch motivations
		const fetchTreatmentMotivationsArray = async () => {
			const response: { [key: string]: string } = await i18n.getResource(i18n.language, 'survey', 'treatmentMotivations')

			const arr: Option[] = []
			for (const [key, value] of Object.entries(response)) {
				arr.push({
					name: value,
					value: key,
				})
			}
			setTreatmentMotivationsArray(arr)
		}

		if (treatmentMotivationsArray === undefined) {
			fetchTreatmentMotivationsArray()
		}

	}, [dataProvider, willUpdate, props, brands, sideEffects, treatmentMotivationsArray, i18n])


	return (
		<section>
			<fieldset className={classes.formStep}>

				<h2 className={`title-h5 ${classes.formTitle}`}>
					{t('survey.birthControlMotivations')}
				</h2>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} htmlFor="reasons">{t('survey:questions.treatmentMotivations')}</label>
					{treatmentMotivationsArray !== undefined && <Autocomplete
						multiple
						id="reasons"
						value={state.treatmentMotivations}
						options={treatmentMotivationsArray}
						getOptionLabel={(option) => option.name}
						onChange={(event, value) => {
							handleChange('treatmentMotivations', event, value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder={t('survey:questions.selectAll')}
							/>
						)}
					/>}

					{(state.treatmentMotivations!.findIndex((reason) => reason.value === 'other') !== -1) && (
						<FormControl variant="outlined" className={classes.formControl}>
							<label className={classes.label}>{t('survey:questions.pleaseDescribe')}</label>
							<TextField
								id="otherTreatmentMotivations"
								variant="outlined"
								value={state.otherTreatmentMotivations || ""}
								fullWidth={true}
								onChange={(event) => {
									handleChange('otherTreatmentMotivations', event, event.target.value)
								}}
							/>
						</FormControl>
					)}

				</FormControl>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} htmlFor="otherTreatmentsUsed">{t('survey:questions.otherContraceptionUsed.question')}</label>
					<Autocomplete
						multiple
						id="otherTreatmentsUsed"
						value={state.otherTreatmentsUsed}
						options={otherContraceptionUsedOptions}
						isOptionEqualToValue={(option, value) => option.value === value.value}
						getOptionLabel={(option) => option.name}
						onChange={(event, value) => {
							handleChange('otherTreatmentsUsed', event, value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder={t('survey:questions.selectAll')}
							/>
						)}
					/>
				</FormControl>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} htmlFor="preferredPeriodFrequency">{t('survey:questions.preferredPeriodFrequency.question')}</label>
					<p className={classes.fieldNotice}>{t('survey:questions.preferredPeriodFrequency.notice')}</p>
					<Select
						id="preferredPeriodFrequency"
						value={state.preferredPeriodFrequency}
						displayEmpty
						fullWidth={true}
						onChange={(event) => {
							handleChange('preferredPeriodFrequency', event, event.target.value)
						}}
					>
						{periodFrequencyOptions.map(function (option, index) {
							return (
								<MenuItem key={index} value={option.value}>{option.name}</MenuItem>
							)
						})}
					</Select>
				</FormControl>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} htmlFor="concernedAbout">{t('survey:questions.concernedAbout')}</label>

					{sideEffects !== undefined && <Autocomplete
						multiple
						id="concernedAbout"
						value={state.concernedAbout}
						options={sideEffects}
						getOptionLabel={(option) => option.name}
						onChange={(event, value) => {
							handleChange('concernedAbout', event, value)
						}}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="outlined"
								placeholder={t('survey:questions.top5concerns')}
							/>
						)}
					/>}
				</FormControl>

			</fieldset>
			<fieldset className={classes.formStep}>

				<h2 className={`title-h5 ${classes.formTitle}`}>
					{t('survey.birthControlHistory')}
				</h2>

				<FormControl variant="outlined" className={classes.formControl}>
					<label className={classes.label} htmlFor="previousTreatments_0">{t('survey:questions.previousTreatments_0')}</label>

					{/* TODO Make the search autocomplete. */}
					<Select
						id="previousTreatments_0"
						value={state.treatmentHistory!.length > 0 ? state.treatmentHistory![0].brandId : ''}
						displayEmpty
						fullWidth={true}
						onChange={async (event) => {
							const brandId = event.target.value as string
							const brand = await dataProvider.getBrand(brandId)
							if (state.treatmentHistory!.length > 0) {
								handleChangePreviousTreatmentChange('brandId', 0, brand!.id)
								handleChangePreviousTreatmentChange('treatmentId', 0, brand!.treatmentId)
							} else {
								addPreviousTreatment(brand!)
							}
						}}
					>
						{brands !== undefined && brands.map(function (option: TreatmentBrand, index: number) {
							return (
								<MenuItem key={index} value={option.id}>{option.name}</MenuItem>
							)
						})}
					</Select>
				</FormControl>

				{state.treatmentHistory!.map(function (_previousTreatment, index) {
					return (<div key={`previousTreatment_` + index} className={classes.repeatableQuestion}>
						{/* TODO Add a delete button to clear the info for this pill and not submit it. */}
						<div className={'hideUntilPreviousQuestionIsAnswered'}>
							<FormControl variant="outlined" className={classes.formControl}>
								<label className={classes.label} htmlFor={`treatmentDuration_${index}`}>{t('survey:questions.treatmentDuration.question')}</label>
								<Select
									id={`treatmentDuration_${index}`}
									value={state.treatmentHistory![index].duration}
									displayEmpty
									fullWidth={true}
									onChange={(event) => {
										handleChangePreviousTreatmentChange('duration', index, event.target.value)
									}}
								>
									{durations.map(function (option: any, index: any) {
										return (
											<MenuItem key={index} value={option.name}>{option.name}</MenuItem>
										)
									})}
								</Select>
							</FormControl>

							<FormControl variant="outlined" className={classes.formControl}>
								<label className={classes.label} htmlFor={`treatmentEffects_${index}`}>{t('survey:questions.treatmentEffects')}</label>

								{treatmentMotivationsArray !== undefined && <Autocomplete
									multiple
									id={`treatmentEffects_${index}`}
									options={treatmentMotivationsArray}
									getOptionLabel={(option: any) => option.name}
									onChange={(event, value) => {
										handleChangePreviousTreatmentChange('treatmentEffects', index, value)
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											placeholder={t('survey:questions.selectAll')}
										/>
									)}
								/>}
							</FormControl>

							<FormControl variant="outlined" className={classes.formControl}>
								<label className={classes.label}>{t('survey:questions.pleaseDescribe')}</label>
								<TextField
									id={`otherTreatmentEffects_${index}`}
									variant="outlined"
									value={state.treatmentHistory![index].otherTreatmentEffects}
									fullWidth={true}
									onChange={(event) => {
										handleChangePreviousTreatmentChange('otherTreatmentEffects', index, event.target.value)
									}}
								/>
							</FormControl>

							<FormControl variant="outlined" className={classes.formControl}>
								<label className={classes.label} htmlFor={`sideEffects_${index}`}>{t('survey:questions.treatmentSideEffects')}</label>

								{sideEffects !== undefined && <Autocomplete
									multiple
									id={`sideEffects_${index}`}
									options={sideEffects}
									getOptionLabel={(option: any) => option.name}
									onChange={(event, value) => {
										handleChangePreviousTreatmentChange('sideEffects', index, value)
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant="outlined"
											placeholder={t('survey:questions.selectAll')}
										/>
									)}
								/>}
							</FormControl>

							<h3 className={`title-h6 ${classes.formTitle}`}>{t('survey:questions.treatmentReview.title')}</h3>
							<FormControl variant="outlined" className={classes.formControl}>
								<label className={classes.label}>{t('survey:questions.treatmentReview.question')}</label>
								<TextField
									id={`review_${index}`}
									variant="outlined"
									value={state.treatmentHistory![index].review}
									fullWidth={true}
									onChange={(event) => {
										handleChangePreviousTreatmentChange('review', index, event.target.value)
									}}
								/>
							</FormControl>

							<FormControl variant="outlined" className={classes.formControl}>
								<label className={classes.label}>{t('survey:questions.treatmentReview.rating')}</label>

								<RadioGroup
									row
									id={`rating_${index}`}
									value={state.treatmentHistory![index].rating !== undefined ? state.treatmentHistory![index].rating : ""}
									onChange={(event) => {
										const value = parseFloat(event.target.value)
										handleChangePreviousTreatmentChange('rating', index, value)
									}}
								>
									<FormControlLabel labelPlacement="end" value={0.0} control={<Radio />} label="0" />
									<FormControlLabel labelPlacement="end" value={0.1} control={<Radio />} label="1" />
									<FormControlLabel labelPlacement="end" value={0.2} control={<Radio />} label="2" />
									<FormControlLabel labelPlacement="end" value={0.3} control={<Radio />} label="3" />
									<FormControlLabel labelPlacement="end" value={0.4} control={<Radio />} label="4" />
									<FormControlLabel labelPlacement="end" value={0.5} control={<Radio />} label="5" />
									<FormControlLabel labelPlacement="end" value={0.6} control={<Radio />} label="6" />
									<FormControlLabel labelPlacement="end" value={0.7} control={<Radio />} label="7" />
									<FormControlLabel labelPlacement="end" value={0.8} control={<Radio />} label="8" />
									<FormControlLabel labelPlacement="end" value={0.9} control={<Radio />} label="9" />
									<FormControlLabel labelPlacement="end" value={1.0} control={<Radio />} label="10" />
								</RadioGroup>
							</FormControl>
						</div>

						<hr />

						<FormControl variant="outlined" className={classes.formControl}>
							<label className={classes.label} htmlFor={`previousTreatments_${index + 1}`}>{t('survey:questions.previousTreatments_x')}</label>
							<Select
								id={`previousTreatments_${index + 1}`}
								value={state.treatmentHistory![index + 1] ? state.treatmentHistory![index + 1].brandId : ''}
								displayEmpty
								fullWidth={true}
								onChange={async (event) => {
									const brandId = event.target.value as string
									const brand = await dataProvider.getBrand(brandId)
									if (state.treatmentHistory![index + 1]) {
										handleChangePreviousTreatmentChange('brandId', index + 1, brand!.id)
										handleChangePreviousTreatmentChange('treatmentId', index + 1, brand!.treatmentId)
									} else {
										addPreviousTreatment(brand!)
									}
								}}
							>
								{brands !== undefined && brands.map(function (option, index) {
									return (
										<MenuItem key={index} value={option.id}>{option.name}</MenuItem>
									)
								})}
							</Select>
						</FormControl>
					</div>)
				})}
			</fieldset>

			<Button type="submit"
				onClick={beforeSubmit}
				className={classes.btn_submit}
				variant="contained"
			>
				{t('survey.save')}
			</Button>
		</section>
	)
}