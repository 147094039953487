export interface LocaleMapping {
	[locale: string]: string
}

export interface LocaleMappings {
	[locale: string]: string[]
}

/**
 * Specific information for how this treatment relates to an effect.
 */
export class TreatmentEffectStats {
	/**
	 * @param id The treatment ID when this is held within an EffectStats, otherwise the effect ID when this is held within a TreatmentStats.
	 * @param prob The probability of seeing the effect for the treatment.
	 * @param name The name of the treatment or the side effect.
	 */
	constructor(
		public id: string,
		public prob: number,
		public name?: LocaleMapping,
	) { }
}

export interface EffectStats {
	id: string
	name: LocaleMapping
	description: LocaleMapping
	treatments: { [groupId: string]: TreatmentEffectStats[] }
	altNames?: LocaleMappings
}

export interface TreatmentBrand {
	id: string
	treatmentId?: string
	name: string
	regions: string[]
}

export interface TreatmentStats {
	id: string
	name: LocaleMapping
	description: LocaleMapping
	metadata: any
	effects: { [groupId: string]: TreatmentEffectStats[] }
	brands: TreatmentBrand[]
}

export interface GroupInfo {
	id: string
	name: LocaleMapping
	isCombination?: boolean
}

export interface GroupInfos {
	[groupId: string]: GroupInfo
}

interface AllData {
	groups: GroupInfos
	effects: { [effectId: string]: EffectStats }
	treatments: { [treatmentId: string]: TreatmentStats }
}

export class DataProvider {
	public static isValidGroup(validGroups: GroupInfos, groups: string[]): boolean {
		groups.sort()
		const groupId = groups.join(',')
		return validGroups[groupId] !== undefined
	}

	private static data: AllData

	constructor() {
		// TODO Make sure loading only happens once even when you click on other pages.
		if (DataProvider.data === undefined) {
			DataProvider.data = this.setupData()
		}
	}

	private setupData() {
		console.log("Loading data")

		// Just load from a file for now.
		// eslint-disable-next-line @typescript-eslint/no-var-requires
		const data: AllData = require('./all_data.json')

		for (const effect of Object.values(data.effects)) {
			if (effect.treatments) {
				continue
			}
			effect.treatments = {}

			for (const treatment of Object.values(data.treatments)) {
				for (const groupId of Object.keys(data.groups)) {
					if (!(groupId in treatment.effects)) {
						continue
					}
					for (const treatmentEffectStats of treatment.effects[groupId]) {
						if (treatmentEffectStats.id === effect.id) {
							if (!effect.treatments[groupId]) {
								effect.treatments[groupId] = []
							}
							effect.treatments[groupId].push(new TreatmentEffectStats(treatment.id, treatmentEffectStats.prob, treatment.name))
						}
					}
				}
			}

			for (const groupId of Object.keys(data.groups)) {
				if (effect.treatments[groupId]) {
					effect.treatments[groupId] = effect.treatments[groupId].sort((s1, s2) => {
						return s2.prob - s1.prob
					})
				}
			}
		}

		// TODO Validate the data more.
		// Make sure groupIds used are valid.
		if (data.groups['all'] === undefined) {
			console.warn("The 'all' group is missing.")
		}
		return data
	}

	/**
	 * @returns All the different demographics that we recognize.
	 */
	public async getGroups(): Promise<GroupInfos> {
		return DataProvider.data.groups
	}

	/**
	 * @returns All of the treatments we recognize.
	 * These are the drugs/chemicals.
	 * The brands that use these treatments are listed inside each object.
	 */
	public async getAllTreatments(): Promise<TreatmentStats[]> {
		return Object.values(DataProvider.data.treatments)
	}

	/**
	 * @param region E.g. "US", "CA", "EU", or "UK".
	 * If `undefined`, then all brands are returned.
	 * @returns The brands available in that region.
	 */
	public async getBrands(region?: string): Promise<TreatmentBrand[]> {
		const result = []
		for (const t of await this.getAllTreatments()) {
			for (const b of t.brands) {
				if (region === undefined || b.regions.indexOf(region) > -1) {
					if (!b.treatmentId) {
						b.treatmentId = t.id
					}
					result.push(b)
				}
			}
		}

		result.sort((t1, t2) => {
			return t1.name.localeCompare(t2.name)
		})

		return result
	}

	/**
	 * @param brandId The ID for the brand.
	 * @returns The brand object. `undefined` if it was not found.
	 */
	public async getBrand(brandId: string): Promise<TreatmentBrand | undefined> {
		for (const t of await this.getAllTreatments()) {
			for (const brand of t.brands) {
				if (brand.id === brandId) {
					return brand
				}
			}
		}

		return undefined
	}

	/**
	 * @returns All of the side effects we recognize.
	 */
	public async getAllEffects(): Promise<EffectStats[]> {
		return Object.values(DataProvider.data.effects)
	}

	/**
	 * @param locale The locale of the names to retrieve.
	 * @returns The names of the side effects we recognize.
	 */
	public async getAllEffectNames(locale: string): Promise<string[]> {
		return this.getAllEffects().then(effects => effects.map(e => e.name[locale]))
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async getEffectStats(effectId: string, locale: string): Promise<EffectStats> {
		const result = DataProvider.data.effects[effectId]
		return result
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	public async getTreatment(treatmentId: string, locale: string): Promise<TreatmentStats> {
		const result = DataProvider.data.treatments[treatmentId]

		// Make sure names are set.
		for (const treatmentEffectStats of Object.values(result.effects)) {
			for (const effect of treatmentEffectStats) {
				if (!effect.name) {
					effect.name = DataProvider.data.effects[effect.id].name
				}
			}
		}
		return result
	}
}
