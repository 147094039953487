import produce from 'immer'
import { Account } from 'msal'
import React from 'react'
import { AuthenticationState } from 'react-aad-msal'
import { authProvider, ensureLoggedIn } from '../authProvider'
import { Submission } from '../user-data/user-data'
import { getObjectProperty, setObjectProperty } from './index'
import { UserAccount, UserAccountProps } from './SurveyProps'

export const useUserAccount = (): UserAccountProps => {
	const [user, setUser] = React.useState<UserAccount>({
		authState: AuthenticationState.Unauthenticated,
		account: undefined,
		data: {} as Submission
	})

	/**
	 * @param account The logged in account.
	 * @returns Some example data.
	 */
	const getSampleUserData = (account: Account) => {
		const userData: Partial<Submission> = {
			user: {
				// Can pre-fill some information like email, country, "state", from `account`
				// but it should be changeable by the user (eventually) in the form if they need to change it.
				region: account.idTokenClaims.state,
				country: account.idTokenClaims.country,

				sourceName: window.location.origin,
				dateOfBirthMs: new Date(new Date().getFullYear() - 20, 0).getTime(),
				isInsured: true,
			},
			medicalHistory: [],
			medicalConditions: [],
			otherConditions: "",
			medications: [],
			otherMedications: "",
			treatmentMotivations: [],
			treatmentHistory: [],
			otherTreatmentsUsed: [],
			preferredPeriodFrequency: 'monthly',
			publishedTimeMs: new Date().getTime(),
			isForTesting: true,
		}

		return userData
	}

	const getUserProperty = (property: string, defaultValue?: any) => {
		const v = getObjectProperty(user, property)
		return (v) ? v : defaultValue
	}

	const setUserProperty = (property: string, value: any) => {
		setUser(
			produce(user => {
				setObjectProperty(user, property, value)
			})
		)
	}

	React.useEffect(() => {
		console.debug("useUserAccount: React.useEffect. user.authState:", user.authState)
		if (user.authState === AuthenticationState.Unauthenticated) {
			authProvider.registerAuthenticationStateHandler((authenticationState: AuthenticationState | undefined) => {
				console.debug("useUserAccount: registerAuthenticationStateHandler: authenticationState:", authenticationState)
				if (authenticationState) {
					user.authState = authenticationState
				}
				if (authenticationState !== AuthenticationState.Authenticated) {
					return
				} else {
					const newUserState = produce(user => {
						user.account = authProvider.getAccount()
						// TODO Try not setting data with example data.
						user.data = getSampleUserData(authProvider.getAccount())
					})

					setUser(newUserState)
				}
			})

			// Declare that login is in progress so that we don't have a redirect loop.
			// Trust that `ensureLoggedIn` will log us in.
			user.authState = AuthenticationState.InProgress
			ensureLoggedIn()
		}

	}, [user])

	return {
		user,
		setUser,
		getUserProperty,
		setUserProperty,
	}
}