import Container from '@mui/material/Container'
import React from 'react'
import { DataProvider, EffectStats } from '../data-retrieval/DataProvider'
import SideEffectIntro from './SideEffectIntro'

/**
 * The page about one side effect.
 */
export default class SideEffect extends React.Component<unknown, {
	locale: string,
	effectId: string,
	effectStats: EffectStats | undefined,
	selectedGroupIds: string[],
}> {
	private dataProvider = new DataProvider()

	constructor(props: unknown) {
		super(props)

		const urlParams = new URLSearchParams(window.location.search)
		const locale = urlParams.get('locale') || 'en'
		const effectId = urlParams.get('id')
		if (!effectId) {
			console.error("No id given.")
			return
		}

		const selectedGroupIds = urlParams.get('groupIds')?.split(",") || ['all']

		this.state = {
			locale,
			effectId,
			effectStats: undefined,
			selectedGroupIds,
		}
	}

	async componentDidMount(): Promise<void> {
		const { locale, effectId } = this.state
		const effectStats = await this.dataProvider.getEffectStats(effectId, locale)

		document.title = effectStats.name[locale] + " - " + document.title

		this.setState({
			locale,
			effectStats,
		})
	}

	render(): React.ReactNode {
		if (!this.state.locale) {
			return <Container><div></div></Container>
		}
		return (<Container>
			<SideEffectIntro
				locale={this.state.locale}
				stats={this.state.effectStats}
				selectedGroupIds={this.state.selectedGroupIds}
			/>
		</Container>)
	}
}