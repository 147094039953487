import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { Theme } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'

const styles = ({ spacing }: Theme) => createStyles({
	root: {
		wordBreak: 'break-word',
	},
	title: {
		marginTop: spacing(2),
	},
	paypalDonationSection: {
		marginBottom: spacing(1),
	},
	paypalDonateButton: {
		display: 'inline',
		marginLeft: '4px',
		position: 'relative',
		top: '6px',
	},
})

class Donate extends React.Component<WithStyles<typeof styles>> {
	paypalDonation(): JSX.Element {
		const { classes } = this.props
		return <form action="https://www.paypal.com/donate" method="post" target="_top"
			className={classes.paypalDonationSection}>
			<p>
				<input type="hidden" name="business" value="jaclynhearnden+paypaldonations@pill0.com" />
				<input type="hidden" name="item_name" value="Contribute to Pill0's mission" />
				<input type="hidden" name="currency_code" value="CAD" />
				PayPal:
				<input type="image"
					className={classes.paypalDonateButton}
					src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="Done with PayPal" alt="Donate with PayPal button"
				/>
			</p>
		</form>
	}

	render(): React.ReactNode {
		const { classes } = this.props
		return (<Container className={classes.root}>
			<h1 className={'title-h4'}>
				Donations
			</h1>
			<p>
				{"Interested in helping us realize our vision?"}
				{" There are many ways for you to donate and contribute to our cause of improving birth control selection for women."}
			</p>
			{this.paypalDonation()}
			<p>
				{"Bitcoin (BTC): bc1qae525xa7zfzyaa66qhzpqp5p5ll789lydvl5uf"}
			</p>
			<p>
				{"Ethereum (ETH): 0xb9099034c74e0f0797c6ee7378f8aabb0807a097"}
			</p>
			<p>
				{"Users of the "}<Link href="https://brave.com" target="_blank">Brave Browser</Link>{" can give us a tip directly through Brave Rewards."}
			</p>
		</Container>)
	}
}

export default withStyles(styles)(Donate)
