import * as d3 from 'd3'
import $ from 'jquery'
import React, { Component } from 'react'
import { PILL0_PINK } from '../theme'
import { ChartData, roundPercent } from './data-helpers'

class BarChart extends Component<{
	id: string
	chartTitle: string
	data: ChartData
	prevData?: ChartData
	maxHeight: number
	width?: number | 'auto'
	maxValue?: number
}> {
	componentDidMount(): void {
		// Only draw when scrolled into view.
		// Modified from https://www.thewebtaylor.com/articles/how-to-detect-if-an-element-is-scrolled-into-view-using-jquery
		const handleScroll = () => {
			const docViewTop = $(window).scrollTop()
			const height = $(window).height()
			const offset = $(`#${this.props.id}`).offset()
			if (docViewTop !== undefined && height !== undefined && offset !== undefined) {
				const docViewBottom = docViewTop + height
				const elemTop = offset.top
				if ((docViewTop <= elemTop) && (elemTop <= docViewBottom)) {
					this.drawChart()
					window.removeEventListener('scroll', handleScroll)
				}
			} else {
				this.drawChart()
				window.removeEventListener('scroll', handleScroll)
			}
		}
		window.addEventListener('scroll', handleScroll)
		handleScroll()
	}

	truncateLabel(label: string): string {
		const maxLength = 22
		if (label.length > maxLength) {
			return label.slice(0, maxLength - 3).trimEnd() + "..."
		}
		return label
	}

	drawChart(): void {
		if (!d3.select(`#${this.props.id} svg`).empty()) {
			d3.select(`#${this.props.id} svg`).remove()
		}

		// Modified from https://blog.logrocket.com/data-visualization-in-react-using-react-d3-c35835af16d0/
		const { prevData } = this.props
		let { width, maxValue } = this.props
		let { data, labels } = this.props.data
		const { hrefs } = this.props.data
		if (maxValue === undefined) {
			maxValue = prevData ? Math.max(...data, ...prevData.data) : Math.max(...data)
		}
		const minWidth = 300
		// Nice width for the title to fit on one line.
		const idealMinWidth = 500
		// Spacing for the bar labels.
		const leftSpacing = 25
		const spacingBelowGraph = 100
		const spacingAboveGraph = 140
		// Reduce height so that there is not too much white space between the title and the bars.
		const xAxisHeightFromTop = spacingAboveGraph + maxValue * (this.props.maxHeight - spacingAboveGraph - spacingBelowGraph)
		// TODO Set up min height.
		const chartHeight = xAxisHeightFromTop + spacingBelowGraph
		const barWidth = 52
		const spacingBetweenBars = 6
		const labelMarginBottom = 3

		const scale = d3.scaleLinear()
			.domain([0, maxValue])
			.range([xAxisHeightFromTop, spacingAboveGraph])

		if (width === undefined) {
			width = 'auto'
		}
		if (width === 'auto') {
			const maxWidth = $(`#${this.props.id}`).width() || window.innerWidth
			const maxNumBars = Math.floor((maxWidth - spacingBetweenBars - leftSpacing) / (barWidth + spacingBetweenBars))
			if (maxNumBars < data.length) {
				data = data.slice(0, maxNumBars)
				labels = labels.slice(0, maxNumBars)
			}
			width = leftSpacing + data.length * (barWidth + spacingBetweenBars)
			if (width < minWidth) {
				// So that the title doesn't get squished.
				width = minWidth
			}
			if (width < idealMinWidth && idealMinWidth < maxWidth) {
				width = idealMinWidth
			}
		}

		const hoverTextStartX = (width as number) * 1 / 12
		const hoverTextMaxWidth = (width as number) - hoverTextStartX

		const colors = [
			// darker pink
			'#b7457c',
			PILL0_PINK,
			// purple
			'#675891',
			// orange
			'#f37f65',
			// teal
			'#a8d1cb',
			// yellow
			'#f3b162',

			// From https://material-ui.com/customization/color/
			// "red" 200
			// '#ef9a9a',
			// purple 200
			// '#ce93d8',
			// blue 200
			// '#90caf9',
			// teal 200
			// '#80cbc4',
			// green 200
			// '#a5d6a7',
			// blueGrey 200
			// '#b0bec5',

			// Darker Pink
			// '#ec407a',
			// Darker Darker Pink
			// '#d81b60',
			// Darker Darker Darker Pink
			// '#ad1457'
		]
		const transitionDuration = 2000

		// Ranges
		// const x = d3.scaleTime().range([0, width]);
		// const y = d3.scaleLinear().range([h, 0]);

		const svg = d3.select(`#${this.props.id}`)
			.append("svg")
			.attr("width", width)
			.attr("height", chartHeight)

		// Title
		svg.append("text")
			.attr("x", (width / 2))
			.attr("y", spacingAboveGraph / 2)
			.attr("text-anchor", "middle")
			.style("font-size", "18px")
			.text(this.props.chartTitle)
			.call(wrap, width)

		// Y-axis label.
		svg.append("text")
			.attr("class", "y label")
			.attr("text-anchor", "middle")
			.attr("y", 0)
			.attr("x", -chartHeight / 2)
			.attr("dy", "1em")
			.attr("transform", "rotate(-90)")
			.text("Mentions (%)")

		// Using multiple labels modified from: https://stackoverflow.com/a/56298842/1226799
		const effects = svg.selectAll('g.effects')
			.data(data)
		const effectsEnter = effects.enter()
			.append('g')
			.classed('effect', true)

		function handleMouseOverStat(this: any) {
			const label = this.dataset.name
			svg.append("text")
				.attr("id", "hover-text")
				.attr("x", hoverTextStartX)
				.attr("y", spacingAboveGraph * 3 / 4)
				.attr("class", "hover")
				.attr("font-size", "1em")
				.style("fill", "black")
				.text(function () {
					return `Click to find out more about ${label}`
				})
				.call(wrap, hoverTextMaxWidth)

			const textEl = document.getElementById("hover-text") as any
			svg.insert("rect", "text.hover")
				.attr("class", "hover")
				.attr("x", textEl.getBBox().x)
				.attr("y", textEl.getBBox().y)
				.attr("width", textEl.getBBox().width)
				.attr("height", textEl.getBBox().height)
				.style("fill", "white")
		}

		function handleMouseOutStat(/* _d: any, _i: number */) {
			svg.select("text.hover").remove()
			svg.select("rect.hover").remove()
		}

		function clickStat(this: any, /* _d: any, _i: number */) {
			const urlParams = new URLSearchParams(window.location.search)
			const selectedGroupIds = urlParams.get('groupIds')
			// TODO Check if Ctrl is held and open in new tab.
			window.open(this.getAttribute('href') + (selectedGroupIds ? `&groupIds=${selectedGroupIds}` : ''), '_self')
		}

		// Bars
		effectsEnter
			.append("rect")
			.attr("x", (_: number, i: number) => leftSpacing + i * (barWidth + spacingBetweenBars))
			.attr("y", (_d, i) => {
				if (prevData) {
					const prevValue = prevData.getValue(labels[i])
					if (prevValue) {
						return scale(prevValue.datum)!
					}
				}
				return scale(0)!
			})
			.attr("height", (_d, i) => {
				if (prevData) {
					const prevValue = prevData.getValue(labels[i])
					if (prevValue) {
						return xAxisHeightFromTop - scale(prevValue.datum)!
					}
				}
				return 0
			})
			.attr("width", barWidth)
			.attr("fill", function (_d, index) {
				// TODO It might be nice to use the same color as last in prevData
				// but then we could get the same colors next to each other.
				return colors[index % colors.length]
			})
			.style("cursor", "pointer")
			.attr("href", function (_d, index) {
				return hrefs[index]
			})
			.attr("data-name", function (_d, index) {
				return labels[index]
			})
			.on("mouseover", handleMouseOverStat)
			.on("mouseout", handleMouseOutStat)
			.on("click", clickStat)
			.transition()
			.attr("height", (d: number) => xAxisHeightFromTop - scale(d)!)
			.attr("y", (d: number) => scale(d)!)
			.duration(transitionDuration)
		// To fade in.
		// .transition()
		// .attr("fill", function (_d, index) {
		// 	return colors[index % colors.length]
		// })

		// Percent Above Bar
		effectsEnter
			.append("text")
			.text((_d, i) => {
				if (prevData) {
					const prevValue = prevData.getValue(labels[i])
					if (prevValue) {
						return roundPercent(prevValue.datum)
					}
				}
				return "0%"
			})
			.attr("y", (_d, i) => {
				if (prevData) {
					const prevValue = prevData.getValue(labels[i])
					if (prevValue) {
						return scale(prevValue.datum)! - labelMarginBottom
					}
				}
				return scale(0)! - labelMarginBottom
			})
			.attr("x", (_: number, i: number) => leftSpacing + i * (barWidth + spacingBetweenBars) + Math.max((barWidth - 25) / 2, 0))
			.style("cursor", "pointer")
			.attr("href", function (_d, index) {
				return hrefs[index]
			})
			.attr("data-name", function (_d, index) {
				return labels[index]
			})
			.on("mouseover", handleMouseOverStat)
			.on("mouseout", handleMouseOutStat)
			.on("click", clickStat)
			.transition()
			.duration(transitionDuration)
			.tween("text", function (d: number, index) {
				const thiz = this as SVGTextElement
				let startValue = 0
				if (prevData) {
					const prevValue = prevData.getValue(labels[index])
					if (prevValue) {
						startValue = prevValue.datum
					}
				}
				const i = d3.interpolate(startValue, d)
				return function (t) {
					thiz.textContent = roundPercent(i(t))
				}
			})
			.attr("y", (d: number) => scale(d)! - labelMarginBottom)

		// Labels Below Bars (Drug Name/Side Effect Name)
		effectsEnter
			.append("text")
			.attr("class", "label")
			.attr("dy", ".75em")
			.text((_d, i) => this.truncateLabel(labels[i]))
			.attr('transform', function (_d, i) {
				return `translate(${leftSpacing + i * (barWidth + spacingBetweenBars) - spacingBetweenBars}, ${xAxisHeightFromTop + spacingBelowGraph})rotate(-80)`
			})
			.style("cursor", "pointer")
			.attr("href", function (_d, index) {
				return hrefs[index]
			})
			.attr("data-name", function (_d, index) {
				return labels[index]
			})
			.on("mouseover", handleMouseOverStat)
			.on("mouseout", handleMouseOutStat)
			.on("click", clickStat)
			.call(wrap, barWidth + spacingBetweenBars)
	}

	render(): React.ReactNode {
		return <div id={this.props.id}></div>
	}

	componentDidUpdate(): void {
		this.drawChart()
	}
}

// Modified from https://stackoverflow.com/a/35405267/1226799
function wrap(text: d3.Selection<SVGTextElement, number, SVGSVGElement, unknown> | d3.Selection<SVGTextElement, unknown, HTMLElement, any>, width: number) {
	text.each(function () {
		const text = d3.select(this),
			words = text.text().split(/\s+/).reverse(),
			y = text.attr("y"),
			x = text.attr("x"),
			dy = parseFloat(text.attr("dy")) || 0,
			lineHeight = 1.1 // ems
		let line: string[] = [],
			lineNumber = 0,
			tspan = text.text(null)
				.append("tspan")
				.attr("x", function (d: any) {
					if (x) {
						return x
					}
					return d ? d.children || d._children ? -10 : 10 : width / 2
				})
				.attr("y", y)
				.attr("dy", dy + "em")
		let word
		// eslint-disable-next-line no-cond-assign
		while (word = words.pop()) {
			line.push(word)
			tspan.text(line.join(" "))
			const node = tspan.node()
			if (node) {
				const textWidth = node.getComputedTextLength()
				if (textWidth > width) {
					line.pop()
					tspan.text(line.join(" "))
					line = [word]
					++lineNumber
					tspan = text.append("tspan")
						.attr("x", function (d: any) {
							if (x) {
								return x
							}
							return d ? d.children || d._children ? -10 : 10 : width / 2
						})
						.attr("y", y || 0)
						.attr("dy", lineNumber * lineHeight + dy + "em")
						.text(word)
				}
			}
		}
	})
}
export default BarChart