import $ from 'jquery'
import { getAccessToken } from '../authProvider'
import packageInfo from './../../package.json'

export interface User {
	/**
	 * The country where the user is currently located.
	 * This helps determine what treatments are available to them.
	 */
	country: string
	/**
	 * The state, province, or region (if one can be determined) where the user is currently located.
	 * This helps determine what treatments are available to them.
	 */
	region?: string
	sourceName: string
	/**
	 * The user ID. Not needed in authenticated requests because it is in the token claims.
	 */
	userId?: string
	dateOfBirthMs?: number
	isInsured?: boolean
}

export interface Dose {
	/**
	 * Days with the hormone, and days with the placebo.
	 * Options: 21-7, 24-4, 28-0.
	 */
	style: string
}

/**
 * The review by a user for a specific treatment.
 */
export interface TreatmentHistory {
	/**
	 * The key in the database for this item.
	 * If this is set, then the data with that key will be overwritten using all values in this object.
	 */
	key?: string

	/**
	 * Unique ID generated by the source.
	 */
	idFromSource?: string

	/**
	 * The ID from `TreatmentBrand.treatmentId`.
	 */
	treatmentId: string

	/**
	 * The ID from `TreatmentBrand.id`.
	 */
	brandId: string

	/** An approximation of the number of days that they took the treatment. */
	duration?: string
	/** The number of days that they took the treatment.*/
	durationDays?: number
	/** The side effects experienced. The IDs from `EffectStats.id` should be used. */
	sideEffects?: string[]

	// Some of these fields aren't in the UI/mocks yet but that's okay, Justin will add them later.

	/** Information about the dose taken. */
	dose?: Dose
	/** The language of the review and free text fields. */
	reviewLang?: string
	/** The user's description about how they feel about this treatment. */
	review?: string
	/** The user's rating for the treatment. Score in range from range of 0 (inclusive) to 1 (inclusive): [0,1]. */
	rating?: number

	/** Answers for "Did (this pill):" */
	treatmentEffects?: string[]
	/** Other effects (positive or negative) of the treatment. */
	otherTreatmentEffects?: string
}

/**
 * Information from a user about their experience(s) or needs.
 */
export interface Submission {
	/**
	 * The key in the database for this item.
	 * If this is set, when make a submission, then the data with that key will be overwritten using all values in this object.
	 */
	key?: string

	user: User
	medicalHistory?: string[]
	medicalConditions?: string[]
	/** Free-form list of medical conditions that the patient has. */
	otherConditions?: string
	medications?: string[]
	/** Free-form list of medications that the patient is currently taking. */
	otherMedications?: string
	treatmentMotivations?: string[]
	otherTreatmentMotivations?: string
	treatmentHistory?: TreatmentHistory[]
	otherTreatmentsUsed?: string[]
	preferredPeriodFrequency?: string
	/** Answers for "Which side effects are you most concerned about?" The IDs from `EffectStats.id` should be used. */
	concernedAbout?: string[]

	/**
	 * The patient's age when the review was done.
	 * Not needed if the user's date of birth is provided.
	 */
	patientAge?: number

	/** When the submission was created. Not needed when adding a new submission. Can use `new Date().getTime()`. */
	publishedTimeMs?: number

	/**
	 * `true` Indicates if this able was added as a test case for development or just by a user that is trying out the platform.
	 * `false` (production data) if the data should be used to gather analytics.
	 */
	isForTesting: boolean
}

export interface SetSubmissionResponse {
	key: string
}

export interface GetSubmissionRequest {
	/**
	 * The key for the submission.
	 */
	key: string
}

/**
 * A request to delete some data.
 */
export interface DeleteRequest {
	/**
	 * The key for the data (e.g. submission, treatmentHistory) to delete.
	 */
	key: string
}

export interface GetSubmissionsResponse {
	submissions: Submission[]
}

class UserData {
	constructor(private url: string) {
	}

	/**
	 * Add or update a submission.
	 * If `request.key` is set, then the submission with that key will be overwritten.
	 * If `request.treatmentHistory[*].key` is set, then the treatmentHistory with that key will be overwritten.
	 * @param request The information to save or use to overwrite existing data.
	 */
	async setSubmission(request: Submission): Promise<SetSubmissionResponse> {
		console.debug("setSubmission request:", request)
		const token = await getAccessToken()
		return $.ajax({
			method: 'POST',
			dataType: 'json',
			contentType: 'application/json',
			url: `${this.url}/submission`,
			data: JSON.stringify(request),
			headers: {
				'Authorization': `Bearer ${token}`,
			},
			success: function (response) {
				console.debug("Added submission:", response)
				return response
			},
			error: function (error) {
				console.error("Error adding submission.", error.status, error.responseJSON || error.responseText)
			},
		})
	}

	/**
	 * @param request Specifies the submission to retrieve.
	 * @returns The submission matching the request.
	 */
	async getSubmission(request: GetSubmissionRequest): Promise<Submission> {
		const token = await getAccessToken()
		return $.ajax({
			method: 'GET',
			url: `${this.url}/submission?key=${request.key}`,
			headers: {
				'Authorization': `Bearer ${token}`,
			},
			success: function (response) {
				console.debug("User's submission:", response)
				return response
			},
			error: function (error) {
				console.error(`Error getting user's submission for key ${request.key}.`, error.status, error.responseJSON || error.responseText)
			},
		})
	}

	/**
	 * Delete the submission made by the current user.
	 */
	async deleteSubmission(request: DeleteRequest): Promise<void> {
		const token = await getAccessToken()
		return $.ajax({
			method: 'DELETE',
			url: `${this.url}/submission?key=${request.key}`,
			headers: {
				'Authorization': `Bearer ${token}`,
			},
			success: function (response) {
				console.debug("Delete submission response:", response)
			},
			error: function (error) {
				console.error("Error deleting the user's submission.", error.status, error.responseJSON || error.responseText)
			},
		})
	}

	/**
	 * Delete the treatmentHistory from a submission made by the current user.
	 */
	async deleteTreatmentHistory(request: DeleteRequest): Promise<void> {
		const token = await getAccessToken()
		return $.ajax({
			method: 'DELETE',
			url: `${this.url}/treatmentHistory?key=${request.key}`,
			headers: {
				'Authorization': `Bearer ${token}`,
			},
			success: function (response) {
				console.debug("Delete treatmentHistory response:", response)
			},
			error: function (error) {
				console.error("Error deleting the user's treatmentHistory.", error.status, error.responseJSON || error.responseText)
			},
		})
	}

	/**
	 * @returns The submissions added by the current user.
	 */
	async getSubmissionsFromUser(): Promise<GetSubmissionsResponse> {
		const token = await getAccessToken()
		return $.ajax({
			method: 'GET',
			url: `${this.url}/submissions`,
			headers: {
				'Authorization': `Bearer ${token}`,
			},
			success: function (response) {
				console.debug("User's submissions:", response)
				return response
			},
			error: function (error) {
				console.error("Error getting user's submissions.", error.status, error.responseJSON || error.responseText)
			},
		})
	}

	/**
	 * Create a new user if they don't exist yet.
	 * @param request Information about the user.
	 */
	async addUser(request: User): Promise<void> {
		const token = await getAccessToken()
		return $.ajax({
			method: 'POST',
			url: `${this.url}/user`,
			contentType: 'application/json',
			data: JSON.stringify(request),
			headers: {
				'Authorization': `Bearer ${token}`,
			},
			success: function (response) {
				console.debug("Add user response:", response)
			},
			error: function (error) {
				console.error("Error adding the user.", error.status, error.responseJSON || error.responseText)
			},
		})
	}

	/**
	 * Delete all submissions and data for the current user.
	 */
	async deleteUser(): Promise<void> {
		const token = await getAccessToken()
		return $.ajax({
			method: 'DELETE',
			url: `${this.url}/user`,
			headers: {
				'Authorization': `Bearer ${token}`,
			},
			success: function (response) {
				console.debug("Delete user response:", response)
			},
			error: function (error) {
				console.error("Error deleting the user's submissions.", error.status, error.responseJSON || error.responseText)
			},
		})
	}
}

const urlParams = new URLSearchParams(window.location.search)
const serviceUrl = urlParams.get('serviceUrl') || packageInfo.pill0Config.serviceUrl
export const userData = new UserData(serviceUrl)
