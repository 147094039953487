import { Button, Container } from '@mui/material'
import Link from '@mui/material/Link'
import React from 'react'
import { AuthenticationState } from 'react-aad-msal'
import { useTranslation, withTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { ENABLE_ACCOUNTS, authProvider } from '../authProvider'
import { DataProvider, EffectStats } from '../data-retrieval/DataProvider'
import classes from '../styles/Report.module.css'
import { DeleteRequest, GetSubmissionRequest, Submission, userData } from '../user-data/user-data'
import { useUserAccount } from '../utils/useUserAccount'
import Loading from './Loading'
import SideEffectIntro from './SideEffectIntro'
import { AzureAd } from './react-children-helpers'

function getAge(birthday: Date) { // birthday is a date
	const ageDifMs = Date.now() - birthday.getTime()
	const ageDate = new Date(ageDifMs) // miliseconds from epoch
	return Math.abs(ageDate.getUTCFullYear() - 1970)
}

interface Props {
	isContainerEnabled?: boolean
	report?: Submission
	reportId?: string
}

const Report = (_props: Props): JSX.Element => {
	const { getUserProperty } = useUserAccount()
	const [submission, setSubmission] = React.useState<Submission | null>(null)
	const [sideEffects, setSideEffects] = React.useState<EffectStats[] | undefined>(undefined)
	const [concernedAbout, setConcernedAbout] = React.useState<string[] | undefined>(undefined)
	const [group, setGroup] = React.useState<string[]>(['all'])

	// FIXME: the locale should be set by i18n but sideEffects are provided in english only... I'll hardcode it for now.
	const { t } = useTranslation()
	const locale = 'en' // i18n.language
	const history = useNavigate()
	// Cannot call conditionally, "React Hooks must be called in the exact same order in every component render".
	const location = useLocation()

	let reportId: string | null
	if (_props.report) {
		reportId = _props.report.key!
	} else if (_props.reportId) {
		reportId = _props.reportId
	} else {
		const urlParams = new URLSearchParams(location.search)
		reportId = urlParams.get('id')
	}

	const handleDeleteReport = async (event: any, request: DeleteRequest) => {
		if (window.confirm(t('common.deleteConfirm'))) {
			try {
				await userData.deleteSubmission(request)
				const dashboardPagePath = '/dashboard'
				// If we are on the current submission's page and just deleted it, then we want to go to the Dashboard page so they can see their other reports.
				// If we are already on the Dashboard page, then we want to refresh so that the submission goes away.
				if (location.pathname !== dashboardPagePath) {
					// Reload
					history(dashboardPagePath)
				} else {
					history(0)
				}
			} catch (e) {
				console.error(e)
			}
		}
	}

	const RenderSubmittedData = React.useCallback((concernedAbout?: string[]) => {
		if (concernedAbout === undefined || concernedAbout.length === 0) {
			return (<p>{t('report.noConcerns')}</p>)
		} else if (concernedAbout.length > 0) {
			return (<p>
				{t('report.yourConcerns')}{" "}
				{concernedAbout.join(', ')}
			</p>)
		}
	}, [t])

	// Chart rendering
	const RenderSideEffectCharts = React.useCallback((sideEffects?: EffectStats[]): JSX.Element => {
		if (sideEffects === undefined) {
			// Still loading.
			// Loading information will be shown elsewhere.
			return (<></>)
		} else if (sideEffects.length > 0) {
			return (<div>
				{sideEffects.length > 1 && <section>
					<h6 className='title-h6'>{t('report.jumpTo')}</h6>
					{sideEffects.map((effect, index) => (<div key={index}>
						<Link href={`#${effect.id}`} className='link'>
							{effect.name[locale]}
						</Link>
					</div>))}
				</section>}
				{sideEffects.map((effect, index) => (<div
					key={index} className={classes.sideEffectSection}>
					<SideEffectIntro
						locale={locale}
						stats={effect}
						selectedGroupIds={group}
					/>
				</div>))}
			</div>)
		} else {
			return (<p>{t('report.noSideEffectsToShow')}</p>)
		}
	}, [t, locale, group])

	// Fetch data and do stuff
	React.useEffect(() => {
		const dataProvider = new DataProvider()

		// Age filtering
		const getUserAgeGroup = async (dob: number) => {
			if (dob) {
				const age = getAge(new Date(dob))
				const groups = await dataProvider.getGroups()

				for (const group of Object.keys(groups)) {
					// skip other groups
					const matches = group.match(/age_(\d+)-(\d+)/)
					if (matches && age >= parseInt(matches[1]) && age < parseInt(matches[2])) {
						return [group]
					}
				}
			}
			return ['all'] // default
		}

		// Side Effects mapping
		const fetchSideEffects = async (concernedAbout: string[]) => {
			const concernedAboutNames = []
			const sideEffects = await Promise.all(concernedAbout.map(async c => await dataProvider.getEffectStats(c, locale)))
			for (const effect of sideEffects) {
				concernedAboutNames.push(effect.name[locale])
			}

			setSideEffects(sideEffects)
			setConcernedAbout(concernedAboutNames)
		}

		async function setUpSubmissionInfo(submission: Submission) {
			if (submission.user.dateOfBirthMs) {
				const ageGroup = await getUserAgeGroup(submission.user.dateOfBirthMs)
				setGroup(ageGroup)
			}

			if (submission.concernedAbout) {
				fetchSideEffects(submission.concernedAbout)
			}
		}

		// Fetch Submission sample
		const fetchSample = async (request: GetSubmissionRequest) => {
			try {
				const response = await userData.getSubmission(request)
				setSubmission(response)

				await setUpSubmissionInfo(response)
			} catch (e) {
				console.error(e)
			}
		}

		// Init
		if (!submission) {
			if (_props.report) {
				setSubmission(_props.report)
				setUpSubmissionInfo(_props.report)
			} else if (reportId) {
				fetchSample({ key: reportId })
			}
		}

	}, [submission, reportId])

	const charts = React.useMemo(() => RenderSideEffectCharts(sideEffects), [sideEffects, RenderSideEffectCharts])

	const submittedData = React.useMemo(() => RenderSubmittedData(concernedAbout), [concernedAbout, RenderSubmittedData])

	// Don't wrap in a Container when displaying in the Dashboard page.
	// Also change the title to something like "Your Latest Report".
	// Default to using a container when `_props.isContainerEnabled` is `undefined`.
	const isContainerEnabled = _props.isContainerEnabled !== false
	const contents = (<>
		<h1 className={isContainerEnabled ? 'title-h4' : 'title-h5'}>
			{isContainerEnabled ? t('report.pageTitle') : t('report.pageTitleInContainer')}
		</h1>
		{(authProvider.authenticationState || getUserProperty('authState')) === AuthenticationState.Unauthenticated &&
			<>
				<p hidden={ENABLE_ACCOUNTS}>
					{t('report.notPublic')}
				</p>
				<p>
					{t('common.must_login')}
				</p>
			</>
		}

		<AzureAd provider={authProvider} forceLogin={false}>
			{reportId && (
				<>
					{submission ?
						<>
							{t('reports.submittedOn')} {new Date(submission.publishedTimeMs!).toString()}.

							<p>{t('report.reportText')}</p>
							<details className={classes.showData} open>
								<summary>
									<h2 className="title-h6 title">{t('report.yourData')}</h2>
								</summary>
								<div className={classes.showDataContent}>
									{submittedData}
									{/* The JSON is already logged to the Console. */}
									{/* <code className={classes.dataSample}>
									{JSON.stringify(submission, null, 4)}
								</code> */}
								</div>
							</details>
						</>
						: <Loading />
					}
				</>
			)}

			{/* TODO Give intro to charts. */}
			{charts}

			{submission && <Button
				onClick={(e) => handleDeleteReport(e, { key: submission.key! })}
				className={classes.btn_submit}
				variant="contained">
				{t('report.deleteBtn')}
			</Button>}
		</AzureAd>
	</>)

	if (isContainerEnabled) {
		return (<Container>{contents}</Container>)
	}

	return contents
}

export default withTranslation()(Report)