import { Hidden } from '@mui/material'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import React from 'react'
import { withTranslation } from 'react-i18next'
import { DataProvider, EffectStats, GroupInfo } from '../data-retrieval/DataProvider'
import '../i18n'
import roundImage from '../images/open round33.png'
import swirlImage from '../images/swirl50.png'
import classes from '../styles/Home.module.css'

interface State {
	locale: string
	selectedEffect?: EffectStats | null
	sideEffectOptions?: EffectStats[]
	selectedGroupIds?: string[] | null
	groupOptions?: GroupInfo[]
}

class Home extends React.Component<any, State>{
	dataProvider = new DataProvider()

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	constructor(props: any) {
		super(props)

		const urlParams = new URLSearchParams(window.location.search)
		const locale = urlParams.get('locale') || 'en'
		const selectedGroupIds = urlParams.get('groupIds')?.split(",") || ['all']

		this.state = {
			locale,
			selectedEffect: null,
			sideEffectOptions: undefined,
			selectedGroupIds,
			groupOptions: undefined,
		}
	}

	async componentDidMount(): Promise<void> {
		const sideEffectOptions = await this.dataProvider.getAllEffects()
		this.setState(
			{
				sideEffectOptions,
			}
		)
	}

	render(): React.ReactNode {
		return (<Container>
			<h1 className='title-h4'>
				{this.props.t('home.welcome.title')}
			</h1>

			<div className='section-margin-6'>
				{this.props.t('home.welcome.text')}
			</div>

			<Card className={`${classes.card} pill0-card section`}>
				<CardContent>
					{this.props.t('home.welcome.notice')}
				</CardContent>
			</Card>

			<Grid container spacing={2} className='section' alignItems="center">
				<Grid className='title-h5 center' item xs={12} md={4}>
					<p>{this.props.t('home.surveyintrotext')}</p>
					<Button className={`${classes.btn_home}`} variant="contained" href="/survey">
						{this.props.t('home.surveybutton') as string}
					</Button>
				</Grid>
				<Hidden mdDown>
					<Grid item md={4}></Grid>
				</Hidden>
				<Grid item xs={12} md={4} className='center'>
					<img alt="Round pink pill pack sticker"
						src={roundImage} width={250} />
				</Grid>
				<Hidden mdDown>
					<Grid item xs={12} md={4} className='center'>
						<img alt="Colorful pill pack sticker" src={swirlImage} width={334} />
					</Grid>
					<Grid item xs={12} md={4}></Grid>
				</Hidden>
				<Grid className='title-h5 center' item xs={12} md={4}>
					<p>{this.props.t('home.dashboardintrotext')}</p>
					<Button className={`${classes.btn_home}`} variant="contained" href="/dashboard">
						{this.props.t('home.dashboardbutton') as string}
					</Button>
				</Grid>
				<Hidden mdUp={true}>
					<Grid item xs={12} md={4} className='center'>
						<img alt="Pill pack sticker" src={swirlImage} width={334} />
					</Grid>
				</Hidden>
			</Grid>
		</Container>)
	}
}

export default withTranslation()(Home)
