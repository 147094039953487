import { Button, Card, CardContent } from '@mui/material'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { DataProvider, GroupInfos, TreatmentStats } from '../data-retrieval/DataProvider'
import { updateUrl } from '../url-helper'
import BarChart from './BarChart'
import { ChartData, convertChartData, groupIdComparator, limitData, nameToId, TableItem } from './data-helpers'
import DataTable from './DataTable'

interface Props extends WithTranslation {
	locale: string
	stats?: TreatmentStats | null
	selectedGroupIds: string[]
}

/**
 * A section to show one treatment.
 * Multiple can be listed on one page.
 */
class TreatmentIntro extends React.Component<Props, {
	groups?: GroupInfos
	selectedGroupIds: string[]
}> {
	private dataProvider = new DataProvider()
	private maxChartCount = 15

	private prevData?: ChartData

	constructor(props: Props) {
		super(props)
		const { selectedGroupIds } = this.props

		this.state = {
			groups: undefined,
			selectedGroupIds,
		}
	}

	async componentDidMount(): Promise<void> {
		const groups = await this.dataProvider.getGroups()

		this.setState({
			groups,
		})
	}

	render(): React.ReactNode {
		const { t, stats, locale } = this.props
		const { groups } = this.state
		if (!stats || !groups) {
			return (<div></div>)
		}
		const maxValue = this.getMaxValue()
		const { name: nameMapping, description: descriptionMapping, effects, brands } = stats
		const name = nameMapping[locale]
		const description = descriptionMapping[locale]
		const { data, selectedGroupIds } = this.getData()
		const chartData = limitData(data, this.maxChartCount)
		const prevData = this.prevData
		this.prevData = data

		const headers: TableItem[] = [
			{ value: "Side Effect" },
			{ value: "Mentions (%)", align: 'right' },
		]
		const tableData = convertChartData(headers, data)

		return (<div>
			<h1 className={'title-h4'}>{name}</h1>
			<p>
				{/* TODO Convert markdown. */}
				{description}
			</p>

			{(brands && brands.length > 0) && <p>
				{`Also known as: ${brands.map(b => {
					let result = b.name
					if (b.regions.length > 0) {
						result += ` (${b.regions.join(", ")})`
					}
					return result
				}).join(", ")}`}
			</p>}

			<Card className="pill0-card" variant="outlined">
				<CardContent>
					{data.labels.length > 0 && data.data.length > 0 &&
						<>
							<p>
								{"The following graph shows the percentage of people that mentioned various side effects while using "}{name}{"."}
							</p>
							<p>
								{"The percentages have been skewed slightly to preserve privacy."}
							</p>
							<BarChart
								id={`bar-graph-${nameToId(name)}`}
								chartTitle={`Mentions of Various Side Effects While Using ${name}`}
								data={chartData} maxHeight={500} maxValue={maxValue}
								prevData={prevData} />
							<p>
								{`The most common side effect for the group is ${data.labels[0].toLocaleLowerCase()}, mentioned by ${Math.round(data.data[0] * 100)}% of people using ${name} in our dataset.`}
							</p>
						</>
					}

					{data.labels.length === 0 &&
						<p>
							{"We have data for the people in this group but we didn't detect any side effects mentioned by them."}
						</p>
					}

					{/* Groups */}
					<div>
						<p>
							{"Click on one or more of the groups below to see how the group reports issues differently."}
						</p>

						{Object.keys(effects).sort(groupIdComparator)
							.filter(groupId => !groups[groupId].isCombination)
							.map(groupId => {
								const group = groups[groupId]
								return <Button variant="contained"
									key={`treatment-${groupId}`}
									color={selectedGroupIds.includes(groupId) ? 'primary' : 'secondary'}
									style={{ margin: '5px' }}
									// Disable groups that do not work as a combination.
									disabled={
										// Always allow the 'all' group.
										groupId !== 'all'
										// Allow clicking any group when data for all groups is shown.
										&& selectedGroupIds[0] !== 'all'
										// Allow clicking on the current group (to unselect it).
										&& !selectedGroupIds.includes(groupId)
										// Can this group be added to the current groups to make a valid group?
										&& !DataProvider.isValidGroup(groups, selectedGroupIds.concat(groupId))}
									onClick={() => this.selectGroup(groupId)}>
									{group.name[locale]}
								</Button>
							})}
					</div>
				</CardContent>
			</Card>

			<Card className="pill0-card" variant="outlined">
				<CardContent>
					<p>
						{t('treatment.tableIntro')}
					</p>

					<DataTable data={tableData} />
				</CardContent>
			</Card>
		</div>
		)
	}

	private getMaxValue(): number | undefined {
		const { stats } = this.props
		if (!stats) {
			return undefined
		}
		let result = 0
		for (const effects of Object.values(stats.effects)) {
			for (const effect of effects) {
				if (effect.prob > result) {
					result = effect.prob
				}
			}
		}
		return result
	}

	private getData(): { data: ChartData, selectedGroupIds: string[] } {
		let { selectedGroupIds } = this.state
		let groupId = selectedGroupIds.join(',')
		const data = [], labels = [], hrefs = []
		const { stats, locale } = this.props
		const { groups } = this.state
		if (stats && groups) {
			let effects = stats.effects[groupId]
			if (!effects) {
				// TODO Say there's no data for the combination.
				groupId = 'all'
				effects = stats.effects['all']
				selectedGroupIds = this.getSelectedGroupIds(groupId)
				updateUrl('groupIds', selectedGroupIds.join(','))
			}
			for (const effect of effects) {
				data.push(effect.prob)
				if (effect.name) {
					labels.push(effect.name[locale])
				} else {
					// Shouldn't happen.
					labels.push("")
				}
				hrefs.push(`/effect?id=${effect.id}`)
			}
		}

		return {
			data: new ChartData(data, labels, hrefs),
			selectedGroupIds,
		}
	}

	getSelectedGroupIds(toggledGroupId: string): string[] {
		let selectedGroupIds: string[]
		if (toggledGroupId === 'all') {
			selectedGroupIds = [toggledGroupId]
		} else {
			selectedGroupIds = this.state.selectedGroupIds
			if (selectedGroupIds.length === 1 && selectedGroupIds[0] === 'all') {
				selectedGroupIds = []
			}
			const index = selectedGroupIds.indexOf(toggledGroupId)
			if (index > -1) {
				selectedGroupIds.splice(index, 1)
			} else {
				selectedGroupIds.push(toggledGroupId)
				selectedGroupIds.sort()
			}
		}
		return selectedGroupIds
	}

	selectGroup(toggledGroupId: string): void {
		const selectedGroupIds = this.getSelectedGroupIds(toggledGroupId)
		updateUrl('groupIds', selectedGroupIds.join(','))
		this.setState({ selectedGroupIds })
	}
}

export default withTranslation()(TreatmentIntro)