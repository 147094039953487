import { Button, Container } from '@mui/material'
import React from 'react'
import { AuthenticationState } from 'react-aad-msal'
import { useTranslation, withTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ENABLE_ACCOUNTS, authProvider } from '../authProvider'
import classes from '../styles/Report.module.css'
import { Submission, userData } from '../user-data/user-data'
import { useUserAccount } from '../utils/useUserAccount'
import Loading from './Loading'
import Report from './Report'
import { AzureAd } from './react-children-helpers'

const Dashboard = (): JSX.Element => {
	const { getUserProperty } = useUserAccount()
	const [submissions, setSubmissions] = React.useState<Submission[] | undefined>(undefined)

	const { t } = useTranslation()
	const history = useNavigate()

	const handleDeleteAll = async () => {
		if (window.confirm(t('common.deleteConfirm'))) {
			try {
				await userData.deleteUser()
				// Reload
				history(0)
			} catch (e) {
				console.error(e)
			}

		}
	}

	const RenderSubmissions = React.useCallback((submissions: Submission[] | undefined) => {
		// If no reports are found, then there will already be a message.
		if (submissions === undefined || submissions.length === 0) {
			return undefined
		}

		return (<ul className="reports-list">
			{submissions.map((submission) => (<li key={submission.key}>
				{submission.isForTesting && t('reports.testReport')}
				{t('reports.submittedOn')}
				{new Date(submission.publishedTimeMs!).toString()}

				<Button type="button"
					href={`/report?id=${submission.key}`}
					className={classes.btn_submit}
					variant="contained">
					{t('reports.viewReport')}
				</Button>
			</li>))}
		</ul>)
	}, [])

	React.useEffect(() => {
		const fetchSubmissions = async () => {
			try {
				const response: any = await userData.getSubmissionsFromUser()
				setSubmissions(response.submissions)
			} catch (e) {
				console.error(e)
			}
		}

		if (submissions === undefined) {
			fetchSubmissions()
		}
	}, [])

	const submissionsElement = React.useMemo(() => RenderSubmissions(submissions), [submissions])

	// Get most recent submission to display.
	// Assume the most recent is first and that the back end sorts them.
	const mostRecentSubmission = submissions && submissions.length > 0 ? submissions[0] : undefined

	return (
		<Container>
			<h1 className={'title-h4'}>
				{t('reports.pageTitle')}
			</h1>

			{(authProvider.authenticationState || getUserProperty('authState')) === AuthenticationState.Unauthenticated &&
				<>
					<p hidden={ENABLE_ACCOUNTS}>
						{t('reports.notPublic')}
					</p>
					<p>
						{t('common.must_login')}
					</p>
				</>
			}

			<AzureAd provider={authProvider} forceLogin={false}>
				{submissions ?
					submissions.length ?
						(<>
							<Report report={mostRecentSubmission} isContainerEnabled={false} />

							<div className='box'>
								<p>{t('reports.nbSubmissionsText', { count: submissions.length })}</p>
							</div>

							{submissionsElement}

							<div>
								<Button
									onClick={handleDeleteAll}
									className={classes.btn_submit}
									variant="contained">
									{t('reports.deleteAllBtn')}
								</Button>
							</div>
						</>)
						: <>
							{t('reports.nbSubmissionsText_zero')}
							{/* TODO Say to go to the survey page. */}
						</>
					: <Loading />
				}
			</AzureAd>
		</Container>
	)
}

export default withTranslation()(Dashboard)