import Container from '@mui/material/Container'
import React from 'react'
import { DataProvider, TreatmentStats } from '../data-retrieval/DataProvider'
import TreatmentIntro from './TreatmentIntro'

interface Props { [n: string]: never }

/**
 * The page about one treatment.
 */
export default class Treatment extends React.Component<Props, {
	locale: string,
	treatmentId: string,
	treatmentStats: TreatmentStats | undefined,
	selectedGroupIds: string[],
}> {
	private dataProvider = new DataProvider()

	constructor(props: Props) {
		super(props)

		const urlParams = new URLSearchParams(window.location.search)
		const locale = urlParams.get('locale') || 'en'
		const treatmentId = urlParams.get('id')
		if (!treatmentId) {
			console.error("No treatment id given.")
			return
		}

		const selectedGroupIds = urlParams.get('groupIds')?.split(",") || ['all']

		this.state = {
			locale,
			treatmentId,
			treatmentStats: undefined,
			selectedGroupIds,
		}
	}

	async componentDidMount(): Promise<void> {
		const { locale, treatmentId } = this.state
		const treatmentStats = await this.dataProvider.getTreatment(treatmentId, locale)

		document.title = treatmentStats.name[locale] + " - " + document.title

		this.setState({
			locale,
			treatmentStats,
		})
	}

	render(): React.ReactNode {
		if (!this.state.locale) {
			return <Container><div></div></Container>
		}
		return (<Container>
			<TreatmentIntro
				locale={this.state.locale}
				stats={this.state.treatmentStats}
				selectedGroupIds={this.state.selectedGroupIds}
			/>
		</Container>)
	}
}