import { Button } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import classes from '../styles/Survey.module.css'
import { SurveyProps } from '../utils/SurveyProps'

export const SurveyEnd = (props: SurveyProps): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [state, setState] = React.useState<string>('')

	const { t } = useTranslation()

	const beforeSubmit = (event: any) => {
		props.onSubmit(event)
	}

	React.useEffect(() => {
		setState(JSON.stringify(props.user.data, null, 4))
	}, [props])

	return (
		<section>
			<fieldset className={classes.formStep}>

				<h2 className={`title-h5 ${classes.formTitle}`}>
					{t("survey.end.title")}
				</h2>
				<p>
					{t("survey.end.text")}
				</p>

				{props.reportKey !== undefined && (
					<Button type="submit"
						onClick={beforeSubmit}
						className={classes.btn_submit}
						variant="contained">
						{t("reports.viewReport")}
					</Button>
				)}
			</fieldset>
		</section>
	)

}