import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import React from 'react'
import { DataProvider, EffectStats } from '../data-retrieval/DataProvider'
import classes from '../styles/SideEffects.module.css'
import ListHelper from './ListHelper'

import '../i18n'
import { withTranslation } from 'react-i18next'

const listHelper = new ListHelper()

class SideEffects extends React.Component<any, {
	effects: EffectStats[]
}> {
	dataProvider = new DataProvider()

	async componentDidMount(): Promise<void> {
		document.title = "Side Effects - " + document.title

		const effects = (await this.dataProvider.getAllEffects()).sort((e1, e2) => {
			const highestProb1 = e1.treatments['all'][0].prob
			const highestProb2 = e2.treatments['all'][0].prob
			return highestProb2 - highestProb1
		})
		this.setState({
			effects,
		})
	}

	render(): React.ReactNode {
		const locale = this.props.locale || 'en'
		const { t } = this.props

		return (<Container>
			<h1 className="title-h4">
				{t('sideEffects.title')}
			</h1>
			<p>
				{t('sideEffects.intro')}
			</p>
			<p>
				{t('sideEffects.notice')}
			</p>

			{/* TODO Add search. */}

			{/* TODO Categorize. */}

			<Grid container spacing={2} className={classes.cardContainer}>
				{this.state && this.state.effects && this.state.effects.map(function (effect: EffectStats) {
					const name = effect.name[locale]
					const description = effect.description[locale]
					const url = `/effect?id=${effect.id}`
					return <Grid item key={`effect-${effect.id}`}
						xs={12} sm={4} md={3}
					>
						<Card className={`${classes.card} pill0-card`}>
							<CardActionArea href={url}>
								<CardContent className={classes.cardContent}>
									<p className={`title-h6 ${classes.cardTitle}`}>
										{name}
									</p>
									{description && (
										<p>
											{listHelper.truncateDescription(description)}
										</p>
									)}
								</CardContent>
							</CardActionArea>
							<CardActions className={classes.cardActions}>
								<Button href={url}>
									{t('sideEffects.learnMore')}
								</Button>
							</CardActions>
						</Card>
					</Grid>
				})}
			</Grid>

		</Container>
		)
	}
}

export default withTranslation()(SideEffects)