export interface ChartValue {
	datum: number
	href: string
}

export class ChartData {
	private labelMapping: { [label: string]: ChartValue }

	public constructor(
		public data: number[],
		public labels: string[],
		public hrefs: string[]) {
		this.labelMapping = {}
		for (let i = 0; i < labels.length; ++i) {
			this.labelMapping[labels[i]] = {
				datum: data[i],
				href: hrefs[i],
			}
		}
	}

	public getValue(label: string): ChartValue {
		return this.labelMapping[label]
	}
}

export function getTableColAlignment(item: TableItem): 'inherit' | 'left' | 'center' | 'right' | 'justify' | undefined {
	if (item && typeof item.value === 'number') {
		return 'right'
	}
	return undefined
}

export interface TableItem {
	value: string | number
	align?: 'inherit' | 'left' | 'center' | 'right' | 'justify'
	href?: string
}

export interface TableRow {
	items: TableItem[]
}

export interface TableData {
	headers: TableItem[]
	rows: TableRow[]
}

export function nameToId(name: string): string {
	return name.replace(/[\s/.,()|\\]+/g, '_')
}

export function limitData(data: ChartData, maxCount: number): ChartData {
	return new ChartData(
		data.data.slice(0, maxCount),
		data.labels.slice(0, maxCount),
		data.hrefs.slice(0, maxCount),
	)
}

export function groupIdComparator(g1: string, g2: string): number {
	if (g1 === 'all') {
		return -1
	}
	if (g2 === 'all') {
		return +1
	}
	return g1.localeCompare(g2)
}

/**
 * @param value A number in [0,1].
 * @returns A string between 0% and 100% with no decimal places.
 */
export function roundPercent(value: number): string {
	return `${Math.round(value * 100)}%`
}

export function convertChartData(headers: TableItem[], chartData: ChartData): TableData {
	const rows: TableRow[] = []
	for (let i = 0; i < chartData.labels.length; ++i) {
		const label = chartData.labels[i]
		const href = chartData.hrefs[i]
		// Assume value is a percent.
		const value = roundPercent(chartData.data[i])
		rows.push({
			items: [
				{ value: label, href, },
				{ value, align: 'right' },
			],
		})
	}
	return { headers, rows }
}