import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import React, { ChangeEvent } from 'react'
import { DataProvider, TreatmentStats } from '../data-retrieval/DataProvider'
import classes from '../styles/Treatments.module.css'
import ListHelper from './ListHelper'

import '../i18n'
import { withTranslation } from 'react-i18next'

const listHelper = new ListHelper()

class Treatments extends React.Component<any, {
	locale: string,
	treatments: TreatmentStats[] | undefined
	shownTreatments: TreatmentStats[] | undefined
}> {
	dataProvider = new DataProvider()

	async componentDidMount(): Promise<void> {
		document.title = "Drugs - " + document.title

		const locale = this.props.locale || 'en'
		const treatments = (await this.dataProvider.getAllTreatments()).sort((t1, t2) => {
			const name1 = t1.name[locale] || ""
			const name2 = t2.name[locale] || ""
			return name1.localeCompare(name2)
		})
		this.setState({
			treatments,
			shownTreatments: treatments,
		})
	}

	render(): React.ReactNode {
		const locale = this.props.locale || 'en'
		const { t } = this.props

		const filterOptions = createFilterOptions({
			stringify: (t: TreatmentStats) => t.name[locale].toLocaleLowerCase() + '  ' + t.brands.map(b => b.name).join('  ').toLocaleLowerCase(),
		})

		return (<Container>
			<h1 className="title-h4">
				{t('treatments.title')}
			</h1>
			<p>
				{t('treatments.intro')}
			</p>
			<p>
				{t('treatments.notice')}
			</p>

			{this.state && this.state.treatments && <Autocomplete
				className={classes.search}
				// Close the keyboard on mobile after something is selected.
				blurOnSelect={true}
				clearOnEscape={false}
				clearOnBlur={false}
				filterOptions={filterOptions}
				onChangeCapture={(event: React.ChangeEvent<HTMLInputElement>) => {
					const text = (event.target.value || "").toLocaleLowerCase()
					if (text) {
						this.setState({
							shownTreatments: this.state.treatments!.filter(t => {
								return t.name[locale].toLocaleLowerCase().indexOf(text) > -1 || t.brands.map(b => b.name).join('  ').toLocaleLowerCase().indexOf(text) > -1
							})
						})
					} else {
						this.setState({ shownTreatments: this.state.treatments, })
					}
				}}
				onChange={async (_event: ChangeEvent<any>, selectedTreatment: TreatmentStats | null) => {
					// Once selected
					if (selectedTreatment) {
						this.setState({
							shownTreatments: [selectedTreatment],
						})
					} else {
						this.setState({ shownTreatments: this.state.treatments, })
					}
				}}
				id="treatment-select"
				options={this.state.treatments || []}
				getOptionLabel={(option: TreatmentStats) => option.name[locale]}
				style={{ width: 300 }}
				renderInput={(params: any) => <TextField {...params}
					label={t('treatments.searchFor')}
					variant="outlined"
					InputProps={{
						...params.InputProps,
						className: classes.searchInput,
					}}
				/>}
			/>}

			<Grid container spacing={2} className={classes.cardContainer}>
				{this.state && this.state.shownTreatments && this.state.shownTreatments.map(treatment => {
					const name = treatment.name[locale]
					const description = treatment.description[locale]
					const { brands } = treatment
					const url = `/treatment?id=${treatment.id}`
					return <Grid item key={`treatment-${treatment.id}`}
						sm={12} md={6} lg={4}
					>
						<Card className={`${classes.card} pill0-card`}>
							<CardActionArea href={url}>
								<CardContent className={classes.cardContent}>
									{/* FIXME Make title more prominent when screen size is small. Maybe not setting font sizes explicitly will help. */}
									<p className={`title-h6 ${classes.cardTitle}`}>
										{name}
									</p>
									<p>
										{listHelper.truncateDescription(description)}
									</p>
									<p>
										{`${brands.map(b => {
											const result = b.name
											// Don't show regions for now.
											// if (b.regions.length > 0) {
											// 	result += ` (${b.regions.join(", ")})`
											// }
											return result
										}).join(", ")}`}
									</p>
								</CardContent>
							</CardActionArea>
							<CardActions className={classes.cardActions}>
								<Button href={url}>
									{t('treatments.learnMore')}
								</Button>
							</CardActions>
						</Card>
					</Grid>
				})}
			</Grid>
		</Container>
		)
	}
}

export default withTranslation()(Treatments)
