import { AuthenticationParameters, Configuration } from 'msal'
import { AuthenticationState, IMsalAuthProviderConfig, LoginType, MsalAuthProvider } from 'react-aad-msal'

export const ENABLE_ACCOUNTS = true

// Configuration Azure settings at https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Authentication/appId/c618871a-ea5e-4b99-995d-502676a488d8/isMSAApp/

// Modified from the example at https://github.com/Azure-Samples/ms-identity-b2c-javascript-spa
// and https://medium.com/ascentic-technology/how-to-connect-azure-ad-b2c-with-react-js-b90b63e6b9b7

// Tutorials/Docs:
// https://docs.microsoft.com/en-us/azure/active-directory-b2c/tutorial-single-page-app?tabs=app-reg-auth%2Cconfig-auth%2Creview-auth
// https://docs.microsoft.com/en-us/azure/active-directory-b2c/tutorial-single-page-app-webapi?tabs=app-reg-ga
// https://www.npmjs.com/package/react-aad-msal

/*
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

const b2cPolicies = {
	names: {
		signUpSignIn: 'B2C_1_signupsignin1',
		// forgotPassword: "b2c_1_reset",
		// editProfile: "b2c_1_edit_profile"
	},
	authorities: {
		signUpSignIn: {
			authority: 'https://pill0users.b2clogin.com/pill0users.onmicrosoft.com/B2C_1_signupsignin1',
		},
		// forgotPassword: {
		//     authority: 'https://pill0users.b2clogin.com/pill0users.onmicrosoft.com/b2c_1_reset',
		// },
		// editProfile: {
		//     authority: 'https://pill0users.b2clogin.com/pill0users.onmicrosoft.com/b2c_1_edit_profile'
		// }
	},
	authorityDomain: 'pill0users.b2clogin.com'
}

// The current application coordinates were pre-registered in a B2C tenant.
const apiConfig = {
	b2cScopes: [
		// At least one value is required.
		// FIXME Change the name of this permission/API or make a new one.
		'https://pill0users.onmicrosoft.com/fuck/demo.read',
	],
	// Seems like it isn't needed.
	// webApi: 'https://fabrikamb2chello.azurewebsites.net/hello'
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */

export const msalConfig: Configuration = {
	auth: {
		// This is the ONLY mandatory field; everything else is optional.
		clientId: 'c618871a-ea5e-4b99-995d-502676a488d8',
		authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
		knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
		// redirectUri: "http://localhost:3000", // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
		// Mainly so when you logout on the account page (or a forced login page like the dashboard page) you don't get in a re-login loop.
		postLogoutRedirectUri: window.location.origin,
	},
	cache: {
		cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but 'localStorage' gives you SSO.
		storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
	},
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: AuthenticationParameters = {
	scopes: ['openid', ...apiConfig.b2cScopes],
}

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const authenticationParameters: AuthenticationParameters = {
	scopes: [...apiConfig.b2cScopes],
	// Set this to "true" to skip a cached token and go to the server to get a new token.
	forceRefresh: false,

	// Without the hint, there are errors and login redirects.
	loginHint: 'email',
}

// We get redirect loops.
// Maybe it's because the token expired.
// Using `loginType: LoginType.Popup` might help.
export const authOptions: IMsalAuthProviderConfig = {
	loginType: LoginType.Redirect,
	// The tokenRefreshUri allows you to set a separate page to load only when tokens are being refreshed. When MSAL attempts to refresh a token, it will reload the page in an iframe. This option allows you to inform MSAL of a specific page it can load in the iframe. It is best practice to use a blank HTML file so as to prevent all your site scripts and contents from loading multiple times.
	// We used to add `'/auth.html'` at the end but it caused redirect loops because the page does not exist.
	tokenRefreshUri: window.location.origin,
}

export const authProvider = new MsalAuthProvider(
	msalConfig,
	authenticationParameters,
	authOptions
)

export async function getAccessToken(): Promise<string> {
	console.debug("Getting access token.")
	let result
	try {
		result = (await authProvider.acquireTokenSilent(authenticationParameters)).accessToken
	} catch (err) {
		console.debug("Error getting access token. Will try to get a new one.", err)
		// Can happen when there is a timeout getting the token.
		// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/FAQ.md#how-do-i-renew-tokens-with-msaljs recommends this.
		// TODO Notify to say to allow the pop-up.
		result = (await authProvider.acquireTokenPopup(authenticationParameters)).accessToken
	}

	console.debug("Got access token.")
	return result
}

export async function ensureLoggedIn(): Promise<void> {
	// The state could be undefined but it could change to Authenticated right after because the log in is in progress.
	console.debug("ensureLoggedIn: authProvider.authenticationState:", authProvider.authenticationState)
	if (authProvider.authenticationState === AuthenticationState.Unauthenticated) {
		console.debug("ensureLoggedIn: Calling loginRedirect")
		authProvider.loginRedirect(loginRequest)
	}
}
