import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import StylesProvider from '@mui/styles/StylesProvider'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import CustomAppBar from './AppBar'
import Account from './components/Account'
import Dashboard from './components/Dashboard'
import Documentation from './components/Documentation'
import Donate from './components/Donate'
import Footer from './components/Footer'
import Home from './components/Home'
import Report from './components/Report'
import SideEffect from './components/SideEffect'
import SideEffects from './components/SideEffects'
import Survey from './components/Survey'
import Treatment from './components/Treatment'
import Treatments from './components/Treatments'
import { PILL0_PINK } from './theme'


// The following is needed if we use @mui/styles together with @mui/material to augment the DefaultTheme: https://mui.com/material-ui/migration/v5-style-changes/#%E2%9C%85-add-module-augmentation-for-defaulttheme-typescript
// Things seem to look fine without it, but it can be tricky to correct things if this isn't known, so we'll keep this here but commented out.
// declare module '@mui/styles/defaultTheme' {
// 	// eslint-disable-next-line @typescript-eslint/no-empty-interface
// 	interface DefaultTheme extends Theme { }
// }

const theme = createTheme({
	components: {
		MuiButton: {
			styleOverrides: {
				root: ({ _ownerState }) => ({
					'&:hover': {
						color: 'white',
						backgroundColor: PILL0_PINK,
					},
				}),
			},
		},
	},
	palette: {
		primary: {
			// Used for some links and buttons.
			main: PILL0_PINK,
		},
		secondary: {
			// For some buttons.
			main: '#ddd',
		},
	},
	typography: {
		fontFamily: ['Inter', 'Arial', 'sans-serif'].join(','),
	},
})

const styles = () => createStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '1rem',
		minHeight: '70vh',
		justifyContent: 'space-between',
	},
})

class App extends React.Component<WithStyles<typeof styles>> {
	render(): React.ReactNode {
		const { classes } = this.props
		return (<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<StylesProvider>
					<BrowserRouter>
						<CustomAppBar />
						<div className={classes.root}>
							<Routes>
								<Route path="/account" element={<Account />} />
								<Route path="/dashboard" element={<Dashboard />} />
								<Route path="/reports" element={<Dashboard />} />
								<Route path="/documentation" element={<Documentation />} />
								<Route path="/donate" element={<Donate />} />
								<Route path="/drugs" element={<Treatments />} />
								<Route path="/treatments" element={<Treatments />} />
								<Route path="/treatment" element={<Treatment />} />
								<Route path="/drug" element={<Treatment />} />
								<Route path="/effects" element={<SideEffects />} />
								<Route path="/effect" element={<SideEffect />} />
								<Route path="/survey/:slug" element={<Survey />} />
								<Route path="/survey" element={<Survey />} />
								<Route path="/report" element={<Report />} />
								<Route path="/" element={<Home />} />
							</Routes>
						</div>
					</BrowserRouter>
					<Footer />
				</StylesProvider>
			</ThemeProvider>
		</StyledEngineProvider>)
	}
}

export default withStyles(styles)(App)
