import React from 'react'
import logoImg from '../images/logo_black_transparent.png'

class Loading extends React.Component {
	render(): React.ReactNode {
		return (<div className="center section loader">
			<img alt="Loading animation"
				className="loader rotate"
				src={logoImg} />
		</div>)
	}
}

export default Loading