import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import translationEN from './i18n/en.json'
import translationFR from './i18n/fr.json'
import surveyEN from './i18n/survey.en.json'
import surveyFR from './i18n/survey.fr.json'

i18n
	.use(Backend)
	.use(LanguageDetector)
	// Pass i18n down to react-i18next.
	.use(initReactI18next)
	.init({
		resources: {
			en: {
				translation: translationEN,
				survey: surveyEN
			},
			fr: {
				translation: translationFR,
				survey: surveyFR
			}
		},
		fallbackLng: ['en', 'fr'],
		supportedLngs: [
			'en',
			// Not completely available yet 'fr',
		],
		interpolation: {
			// Not needed for react as it escapes by default.
			escapeValue: false,
		},
		react: {
			useSuspense: false,
		},
		debug: false
	}, (err, t) => {
		if (err) {
			return console.error("Error loading i18n.", err)
		}
		// Same as `i18next.t`.
		t('key')
	})

export default i18n