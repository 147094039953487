import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import FavoriteIcon from '@mui/icons-material/Favorite'
import HomeIcon from '@mui/icons-material/Home'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Drawer from '@mui/material/Drawer'
import Hidden from '@mui/material/Hidden'
import IconButton from '@mui/material/IconButton'
import MuiLink from '@mui/material/Link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Toolbar from '@mui/material/Toolbar'
import { TFunction } from 'i18next'
import React from 'react'
import { AuthenticationState } from 'react-aad-msal'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { authProvider, ENABLE_ACCOUNTS } from './authProvider'
import LanguageSwitch from './components/LanguageSwitch'
import './i18n'
import logoImg from './images/logo_with_Pill0_black_transparent.png'
import classes from './styles/AppBar.module.css'

interface Props {
	t: TFunction
}

class CustomAppBar extends React.Component<Props, {
	authenticationState: AuthenticationState | undefined
	isDrawerOpen: boolean
}> {
	constructor(props: Props) {
		super(props)

		this.state = {
			authenticationState: undefined,
			isDrawerOpen: false,
		}

		this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
		this.handleDrawerClose = this.handleDrawerClose.bind(this)
	}

	componentDidMount(): void {
		authProvider.registerAuthenticationStateHandler(authenticationState => {
			this.setState({ authenticationState })
		})
	}

	handleDrawerOpen(): void {
		this.setState({ isDrawerOpen: true })
	}

	handleDrawerClose(): void {
		this.setState({ isDrawerOpen: false })
	}

	appBarAccountButtons(): React.ReactNode {
		switch (authProvider.authenticationState || this.state.authenticationState || AuthenticationState.Unauthenticated) {
			case AuthenticationState.Authenticated:
				return <>
					<MuiLink href={'/account'} className={classes.accountLink}>
						<AccountCircleIcon
							aria-label={this.props.t('header.account')}
							titleAccess={this.props.t('header.account')}
							className={classes.btn_account}
						/>
					</MuiLink>
					<ExitToAppIcon
						aria-label={this.props.t('header.logout')}
						titleAccess={this.props.t('header.logout')}
						className={classes.btn_account}
						onClick={() => authProvider.logout()}
					/>
				</>
			case AuthenticationState.Unauthenticated:
				return <AccountCircleIcon aria-label={this.props.t('header.login')}
					className={classes.btn_account}
					onClick={() => authProvider.login()}
				/>
			case AuthenticationState.InProgress:
				return <CircularProgress aria-label={this.props.t('header.loginIn')}
					color="inherit" size={32} />
		}
	}

	drawerAccountButtons(): React.ReactNode {
		switch (authProvider.authenticationState || this.state.authenticationState || AuthenticationState.Unauthenticated) {
			case AuthenticationState.Authenticated:
				return <>
					<ListItem button
						component={Link} to='/account'
						onClick={this.handleDrawerClose}>
						<ListItemIcon><AccountCircleIcon color='primary' /></ListItemIcon>
						<ListItemText primary={this.props.t('header.account') as string} />
					</ListItem>
					<ListItem button
						onClick={() => {
							authProvider.logout()
							this.handleDrawerClose()
						}}
					>
						<ListItemIcon><ExitToAppIcon /></ListItemIcon>
						<ListItemText primary={this.props.t('header.logout') as string} />
					</ListItem>
				</>
			case AuthenticationState.Unauthenticated:
				return <ListItem button
					onClick={() => {
						authProvider.login()
						this.handleDrawerClose()
					}}
				>
					<ListItemIcon><AccountCircleIcon /></ListItemIcon>
					<ListItemText primary={this.props.t('header.login') as string} />
				</ListItem>
			case AuthenticationState.InProgress:
				return <ListItem>
					<ListItemIcon>
						<CircularProgress color="inherit" size={24} />
					</ListItemIcon>
					<ListItemText primary={this.props.t('header.loginIn') as string} />
				</ListItem>
		}
	}

	render(): React.ReactNode {
		return (<>
			<AppBar position="static">
				<Toolbar className={classes.navbar}>
					<Hidden mdUp>
						{/* Button to open side nav. */}
						<IconButton
							edge="start"
							size="large"
							className={classes.btn_toggle}
							onClick={this.handleDrawerOpen}
							title={this.props.t('header.open')}
							aria-label={this.props.t('header.open')}>
							<MenuIcon fontSize="large" />
						</IconButton>
					</Hidden>
					{/* Logo root link. */}
					<a href='/' className={classes.logo}>
						<img id="logo" alt="Logo" src={logoImg} />
					</a>
					<Hidden mdDown>
						<Button className={classes.btn_menu} color="inherit" href="/effects">
							<h6 className='title-h6'>
								{this.props.t('header.sideEffects') as string}
							</h6>
						</Button>
						<Button className={classes.btn_menu} color="inherit" href="/treatments">
							<h6 className='title-h6'>
								{this.props.t('header.drugs') as string}
							</h6>
						</Button>
						{this.state.authenticationState === AuthenticationState.Authenticated && (
							<>
								<Button className={classes.btn_menu} color="inherit" href="/survey">
									<h6 className='title-h6'>
										{this.props.t('header.survey') as string}
									</h6>
								</Button>
								<Button className={classes.btn_menu} color="inherit" href="/dashboard">
									<h6 className='title-h6'>
										{this.props.t('header.reports') as string}
									</h6>
								</Button>
							</>
						)}
						<Button className={`${classes.btn_menu} ${classes.btn_donate}`} variant="contained" href="/donate">
							<FavoriteIcon style={{ marginRight: '0.3em', position: 'relative', top: '-1px' }} />
							{this.props.t('header.donate') as string}
						</Button>
						<div className={classes.ctn_account}
							hidden={!ENABLE_ACCOUNTS &&
								(authProvider.authenticationState || this.state.authenticationState || AuthenticationState.Unauthenticated) === AuthenticationState.Unauthenticated}>
							{this.appBarAccountButtons()}
						</div>
						<LanguageSwitch />
					</Hidden>
				</Toolbar>
			</AppBar>
			<Hidden mdUp>
				<Drawer
					variant="persistent"
					anchor="left"
					open={this.state.isDrawerOpen}
					classes={{
						paper: classes.drawer,
					}}
				>
					<div className={classes.drawerHeader}>
						<IconButton
							onClick={this.handleDrawerClose}
							title={this.props.t('header.close')}
							aria-label={this.props.t('header.close')}
							size="large">
							<MenuIcon fontSize="large" />
						</IconButton>
					</div>
					<List>
						<ListItem button component={Link} to='/' onClick={this.handleDrawerClose}>
							<ListItemIcon><HomeIcon /></ListItemIcon>
							<ListItemText primary={this.props.t('header.home') as string} />
						</ListItem>
						<ListItem button component={Link} to='/effects' onClick={this.handleDrawerClose}>
							<ListItemIcon><span role="img" aria-label="sick emoji">🤒</span></ListItemIcon>
							<ListItemText primary={this.props.t('header.sideEffects') as string} />
						</ListItem>
						<ListItem button component={Link} to='/drugs' onClick={this.handleDrawerClose}>
							<ListItemIcon><span role="img" aria-label="pill icon">💊</span></ListItemIcon>
							<ListItemText primary={this.props.t('header.drugs') as string} />
						</ListItem>
						<ListItem button component={Link} to='/survey' onClick={this.handleDrawerClose}>
							<ListItemIcon><span role="img" aria-label="survey icon">📃</span></ListItemIcon>
							<ListItemText primary={this.props.t('header.survey') as string} />
						</ListItem>
						<ListItem button component={Link} to='/dashboard' onClick={this.handleDrawerClose}>
							<ListItemIcon><span role="img" aria-label="icon">📊</span></ListItemIcon>
							<ListItemText primary={this.props.t('header.reports') as string} />
						</ListItem>
						{/* TODO Add Account page link. */}
						<ListItem button component={Link} to='/donate' onClick={this.handleDrawerClose}>
							<ListItemIcon><FavoriteIcon /></ListItemIcon>
							<ListItemText primary={this.props.t('header.donate') as string} />
						</ListItem>
						<div hidden={!ENABLE_ACCOUNTS &&
							(authProvider.authenticationState || this.state.authenticationState || AuthenticationState.Unauthenticated) === AuthenticationState.Unauthenticated}>
							{this.drawerAccountButtons()}
						</div>
					</List>
				</Drawer>
			</Hidden>
		</>)
	}
}

export default withTranslation()(CustomAppBar)