import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import makeStyles from '@mui/styles/makeStyles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import React from 'react'
import { getTableColAlignment, TableData } from './data-helpers'

const useStyles = makeStyles({
	tableContainer: {
		// 'fit-content' might not be great in general but it seems to work fine here.
		maxWidth: 'fit-content',
	},
	table: {
		minWidth: 250,
	},
})

interface Props {
	data: TableData
}

export default function DataTable(props: Props): JSX.Element {
	const classes = useStyles()
	const { data } = props

	if (!data || !data.rows || !data.rows[0] || !data.rows[0].items || !data.rows[0].items[0]) {
		return (<></>)
	}

	return (<TableContainer
		className={classes.tableContainer}
		component={Paper}>
		<Table className={classes.table} aria-label="simple table">
			<TableHead>
				<TableRow>
					{data.headers.map((header, index) => {
						const { value, href } = header
						const align = header.align || getTableColAlignment(data.rows[0].items[index])
						return (
							<TableCell key={`header-${index}`}
								align={align}
							>
								{href ?
									<Link href={href} className="link">
										{value}
									</Link>
									: value}
							</TableCell>)
					})}
				</TableRow>
			</TableHead>
			<TableBody>
				{data.rows.map((row, rowIndex) => (
					<TableRow key={`row-${rowIndex}`}>
						{row.items.map((item, colIndex) => {
							const key = `${rowIndex}-${colIndex}`
							const { value, href } = item
							const align = item.align || getTableColAlignment(item)
							return (<TableCell key={key}
								component={colIndex === 0 ? "th" : undefined}
								scope={colIndex === 0 ? "row" : undefined}
								align={align}
							>
								{href ?
									<Link href={href} className="link">
										{value}
									</Link>
									: value}
							</TableCell>)
						})}
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>)
}
