import { Container, Link } from '@mui/material'
import { TFunction } from 'i18next'
import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import '../i18n'

interface Props {
	t: TFunction
}

class Documentation extends React.Component<Props> {
	render(): React.ReactNode {
		const { t } = this.props

		return (<Container>
			<h1 className='title-h4'>{t('documentation.title') as string}</h1>
			{/* TODO Jaclyn fill in documentation and figure out if we should link and/or show trademarks. */}

			<Trans i18nKey="documentation.intro">
				We collected online public reviews from <Link className="link" href="https://www.drugs.com/" target="_blank">Drugs.com</Link> and <Link className="link" href="https://www.webmd.com/" target="_blank">WebMD®</Link>. We are not affiliated with any of those organizations.
			</Trans>
			<br /><br />
			<Trans i18nKey="documentation.method">
				<p>
					{"Side effects were collected based on manual review of 250+ records. A list of terms was established and the number of records mentioning each term was collected and normalized.</p><p>Terms were counted a maximum of once per record. Statements indicating a side effect did not occur were taken into account and excluded (i.e. \"I didn't get acne\").</p><p>Like side effects that were described differently were consolidated (i.e. \"acne\" and \"breakouts\")."}
				</p>
			</Trans>

		</Container>)
	}
}

export default withTranslation()(Documentation)