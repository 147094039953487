import Link from '@mui/material/Link'
import { Theme } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { WithStyles } from '@mui/styles'
import createStyles from '@mui/styles/createStyles'
import withStyles from '@mui/styles/withStyles'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import packageInfo from './../../package.json'

const styles = (theme: Theme) => createStyles({
	footer: {
		marginTop: theme.spacing(3),
		padding: theme.spacing(2),
		width: '100%',
		maxHeight: '18rem',
		minHeight: '7rem',
		left: 0,
		bottom: 0,
		right: 0,
	},
})

interface Props extends WithTranslation, WithStyles<typeof styles> {
}

class Footer extends React.Component<Props> {
	render() {
		const { classes, t } = this.props
		return (
			<footer className={classes.footer}>
				<Typography variant="subtitle1" color="textSecondary" align="center">
					Learn more about Pill0 at <Link className="link" href='https://pill0.com' target='_blank'>pill0.com</Link>.
				</Typography>
				<Typography variant="body2" color="textSecondary" align="center">
					<Link className="link" href='/documentation'>
						{t('footer.documentation')}
					</Link>
					&nbsp; &nbsp;
					<Link className="link" href='https://pill0.com/privacy-policy' target='_blank'>
						PRIVACY POLICY
					</Link>
					&nbsp; &nbsp;
					<Link className="link" href='https://pill0.com/user-agreement' target='_blank'>
						USER AGREEMENT
					</Link>
					&nbsp; &nbsp;
					<Link className="link" href='https://pill0.com/terms-of-service' target='_blank'>
						TERMS OF SERVICE
					</Link>
				</Typography>
				<Typography variant="body2" color="textSecondary" align="center">
					© {new Date().getFullYear()} Pill0
					&nbsp; &nbsp;
					<code hidden={true}>v{packageInfo.version}</code>
				</Typography>
			</footer>
		)
	}
}

export default withTranslation()(withStyles(styles)(Footer))