import React from 'react'
import i18n from '../i18n'
import { withTranslation } from 'react-i18next'
import classes from '../styles/AppBar.module.css'

function LanguageSwitch() {
	const changeLanguage = (lng: string) => {
		i18n.changeLanguage(lng)
	}

	// TODO lang: Generalize better with a dropdown when we add more languages.
	if (i18n.languages.length === 1) {
		return <></>
	}

	if (i18n.language === 'en') {
		const otherLang = i18n.languages.filter(lang => lang !== i18n.language)[0]
		return (<div><button className={classes.btn_language} onClick={() => changeLanguage(otherLang)}>{otherLang.toUpperCase()}</button></div>)
	} else {
		let otherLang = 'en'
		if (!i18n.languages.includes(otherLang)) {
			otherLang = i18n.languages.filter(lang => lang !== i18n.language)[0]
		}
		return (<div><button className={classes.btn_language} onClick={() => changeLanguage(otherLang)}>{otherLang.toUpperCase()}</button></div>)
	}
}

export default withTranslation()(LanguageSwitch)