import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import { TFunction } from 'i18next'
import React from 'react'
import { AuthenticationState } from 'react-aad-msal'
import { withTranslation } from 'react-i18next'
import { authProvider } from '../authProvider'
import classes from '../styles/Account.module.css'
import Loading from './Loading'
import { AzureAd } from './react-children-helpers'


interface Props {
	t: TFunction
}

class Account extends React.Component<Props, {
	authenticationState: AuthenticationState | undefined,
}> {
	constructor(props: any) {
		super(props)

		this.state = {
			authenticationState: undefined,
		}
	}

	componentDidMount() {
		authProvider.registerAuthenticationStateHandler(authenticationState => {
			this.setState({ authenticationState })
		})
	}

	render(): React.ReactNode {
		const authenticationState = authProvider.authenticationState || this.state.authenticationState
		const { t } = this.props

		return (<Container className={classes.root}>
			<h1 className={'title-h4'}>
				Your Account
			</h1>
			{authenticationState === undefined && <Loading />}
			{authenticationState === AuthenticationState.Unauthenticated &&
				<div>
					<p>
						{"You must login to view this page."}
					</p>
					<Button onClick={() => authProvider.login()}
						type="button"
						variant="contained">
						{t('header.login') as string}
					</Button>
				</div>
			}

			{/* Forcing login caused too many redirect loops. */}
			<AzureAd provider={authProvider} forceLogin={false}>
				<section>
					<Button href='/dashboard'
						type="button"
						variant="contained">
						{t('header.reports') as string}
					</Button>
				</section>
				<section className="section">
					<Button onClick={() => authProvider.logout()}
						type="button"
						variant="contained">
						{t('header.logout') as string}
					</Button>
				</section>
				<p>
					{"Thank you for setting up your account."}
				</p>
				<p>
					{"Soon you will be able to get personalized information from Pill0."}
				</p>
			</AzureAd>
		</Container>)
	}
}

export default withTranslation()(Account)
