export interface Option {
	name: string
	value: string
}

export const getObjectProperty = (object: any, path: any, defaultValue: any = false): any => {
	// Maybe replace with https://www.npmjs.com/package/jsonpath library?

	// If path is not defined or it has false value
	if (!object || !path) {
		return undefined
	}

	// Check if path is string or array. Regex : ensure that we do not have '.' and brackets
	const pathArray = Array.isArray(path) ? path : path.split(/[,[\].]/g).filter(Boolean)

	// Find value if exist return otherwise return undefined value;
	const retValue = pathArray.reduce((prevObj: string[], key: any) => prevObj && prevObj[key], object)

	if (retValue !== undefined) {
		return retValue
	} else {
		return defaultValue
	}
}


export const setObjectProperty = (object: any, path: string, value: any): boolean => {
	// Maybe replace with https://www.npmjs.com/package/jsonpath library?

	// If path is not defined or it has false value
	if (!path) {
		return false
	}

	// Check if path is string or array. Regex : ensure that we do not have '.' and brackets
	const pathArray = Array.isArray(path) ? path : path.split(/[,[\].]/g).filter(Boolean)

	let prevObject = object

	for (let i = 0; i < pathArray.length - 1; i++) {
		if (prevObject[pathArray[i]] === undefined) {
			prevObject[pathArray[i]] = {}
		}

		prevObject = prevObject[pathArray[i]]
	}

	prevObject[pathArray[pathArray.length - 1]] = value

	return true
}

export const yesNoOptionsToBoolean = (value: number | string): boolean | null => {
	if (value === '') {
		return null
	} else {
		return !!value
	}
}

export const booleanToYesNoOptions = (value: boolean | null | undefined): 0 | 1 | '' => {
	if (value === undefined || value === null) {
		return ''
	} else {
		return value ? 1 : 0
	}
}

// From https://stackoverflow.com/a/2117523/1226799
export const uuidv4 = (): string => {
	return (1e7.toString() + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, cString => {
		const c = parseInt(cString, 10)
		// eslint-disable-next-line no-mixed-operators
		return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
	})
}