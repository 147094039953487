import { Container, Link, TextareaAutosize } from '@mui/material'
import produce from 'immer'
import React from 'react'
import { AuthenticationState } from 'react-aad-msal'
import { Trans, useTranslation, withTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { authProvider, ENABLE_ACCOUNTS } from '../authProvider'
import classes from '../styles/Survey.module.css'
import { userData } from '../user-data/user-data'
import { SurveyProps } from '../utils/SurveyProps'
import { useUserAccount } from '../utils/useUserAccount'
import { BirthControl } from './BirthControl'
import { MedicalHistory } from './MedicalHistory'
import { PersonalInformation } from './PersonalInformation'
import { AzureAd } from './react-children-helpers'
import { SurveyEnd } from './SurveyEnd'

const RenderStep = (props: SurveyProps): JSX.Element => {

	switch (props.slug) {
		case 'medical-history': {
			return (
				<MedicalHistory {...props} />
			)
		}
		case 'birth-control': {
			return (
				<BirthControl {...props} />
			)
		}
		case 'confirmation': {
			return (
				<SurveyEnd {...props} />
			)
		}
		case 'personal-information':
		default: {
			return (
				<PersonalInformation {...props} />
			)
		}
	}
}

const Survey = (): JSX.Element => {

	const { slug } = useParams<{ slug: string }>()
	const history = useNavigate()

	const { user, setUser, getUserProperty, setUserProperty } = useUserAccount()
	const [state, setState] = React.useState('')
	const [reportKey, setReportKey] = React.useState<string | undefined>(undefined)
	const [step, setStep] = React.useState(1)

	const { t } = useTranslation()

	const formSteps = [
		'personal-information',
		'medical-history',
		'birth-control',
		'confirmation',
	]

	const handleSubmit = async (event: any | null) => {

		if (event) {
			event.preventDefault()
		}

		try {
			// TODO Add loading indicator.
			if (step === 1) {
				// Just add the information about the user so that it saves.
				await userData.addUser(user.data.user)
			}

			const sent = await userData.setSubmission(user.data)
			setReportKey(sent.key)

			// Preserve serviceUrl and other params.
			const urlParams = new URLSearchParams(window.location.search)
			if (step === formSteps.length) {
				urlParams.set('id', sent.key)
				history(`/report/?${urlParams}`)
			} else {
				// Go to the next step.
				history(`/survey/${formSteps[step]}?${urlParams}`)
			}

		} catch (err) {
			// TODO Notify the user that there was an error saving.
			console.error("Error saving data.", err)
		}
	}

	React.useEffect(() => {
		setState(JSON.stringify(user.data, null, 4))

		if (reportKey !== undefined) {
			setUser((prevUser) => produce(prevUser, (user) => {
				user.data.key = reportKey
			}))
		}

		if (!slug) {
			history('/survey/' + formSteps[0])
		} else {
			setStep(formSteps.indexOf(slug) + 1)
		}

	}, [user, setStep, formSteps, slug, history, reportKey, setUser])

	return (
		<Container>
			{(authProvider.authenticationState || getUserProperty('authState')) === AuthenticationState.Unauthenticated &&
				<div>
					<h1 className={'title-h4'}>
						{t('survey.pageTitle')}
					</h1>
					<p hidden={ENABLE_ACCOUNTS}>
						{t('survey.notPublic')}
					</p>
					<p>
						{t('common.must_login')}
					</p>
				</div>
			}

			<AzureAd provider={authProvider} forceLogin={false}>
				<form autoComplete="on">
					<section>
						<header className={classes.formHeader}>
							<h1 className={'title-h4'}>{t('survey.title')}</h1>
							<p hidden={ENABLE_ACCOUNTS}>
								<strong>{t('survey.notPublic')}</strong>
							</p>
							<Trans i18nKey="survey.intro">
								Providing data allows us to personalize our recommendations. Read about our <Link className="link" href="https://pill0.com/privacy-policy" target="_blank">data privacy policy</Link>.
							</Trans>

							<p className={classes.infoBox} aria-hidden="false">{t('survey.disclaimer')}</p>

						</header>
						<p className={classes.step}>{step} / 4</p>
						<RenderStep slug={slug!} onSubmit={handleSubmit} user={user} setUser={setUser} getUserProperty={getUserProperty} setUserProperty={setUserProperty} reportKey={reportKey} />
					</section>

					<TextareaAutosize id="userDataJson"
						// Show raw JSON just when debugging.
						hidden={true}
						className={classes.textarea}
						aria-label="Survey input"
						minRows={10}
						placeholder="Enter the JSON to add to our database"
						readOnly
						value={state}
					/>
				</form>
			</AzureAd>
		</Container>
	)
}

export default withTranslation()(Survey)